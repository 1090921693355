import { AxiosResponse } from 'axios';
import { axiosInstance } from './ApiService';
import { CSEPrefill } from '../models/CSEPrefill';
import { Contract } from '../models/Contract';
import { ContractDto } from '../dto/ContractDto';

/** Based on CSE service. */
const CSEService = {
  /**
   * Submit CSE form to pre-fill and sign PDF.
   *
   * @param data CSE pre-fill data.
   */
  async preFillCSE(data: CSEPrefill): Promise<AxiosResponse> {
    return await axiosInstance.post('/user/cse-form', data);
  },

  /**
   * Submit Top-Off form to pre-fill and sign PDF.
   *
   * @param data Top-Off pre-fill data.
   */
  async preFillTopOff(data: CSEPrefill): Promise<AxiosResponse> {
    return await axiosInstance.post('/user/top-off', data);
  },

  /**
   * Submit Combined form to pre-fill and sign PDF.
   *
   * @param data Combined pre-fill data.
   */
  async preFillCombined(data: CSEPrefill): Promise<AxiosResponse> {
    return await axiosInstance.post('/user/combined', data);
  },

  /**
   * Save contracts.
   *
   * @param data contracts data.
   */
  async saveContracts(data: Contract): Promise<AxiosResponse> {
    const dto = mapToContract(data);

    const { data: responseData } = await axiosInstance.post('/user/contracts', dto);

    return responseData;
  },
};

/**
 * Contract mapper function from DTO to model.
 *
 * @param contract Contract instance.
 */
const mapToContract = (contract: Contract): ContractDto => {
  return {
    firstName: contract.firstName,
    lastName: contract.lastName,
    phone: contract.phone,
    email: contract.email,
    storeName: contract.storeName,
    address: contract.address,
    city: contract.city,
    state: contract.state,
    zipCode: contract.zipCode,
    customerAccountName: contract.customerAccountName,
    customerAccountNumber: contract.customerAccountNumber,
    divisionNumbers: contract.divisionNumbers,
    posSystem: contract.posSystem,
    pdiSolutionToEnrollIn: contract.pdiSolutionToEnrollIn,
    contractDocumentId: contract.contractDocumentId,
    cseNumber: contract.cseNumber,
    oneTimeLoginURL: contract.oneTimeLoginURL,
    userName: contract.userName,
    password: contract.password,
    storeCoreMarkAccountNumber: contract.storeCoreMarkAccountNumber,
    salesRepresentativeFullName: contract.salesRepresentativeFullName,
    salesRepresentativeEmail: contract.salesRepresentativeEmail,
  };
};

export default CSEService;
