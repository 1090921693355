import {
  ApplicationSettingsDto,
  CategoryDto,
  CategoryLinkDto,
  DivisionDto,
  DivisionSearchItemDto,
  UserDto,
} from '../dto';
import { ApplicationSettings, Category, CategoryLink, Division, User, UserProfile } from '../models';
import { DivisionSearchItem } from '../models/DivisionSearchItem';
import { axiosInstance } from './ApiService';

/** Based on user profile service. */
const UserProfileService = {
  /** Load all profile data method. */
  async loadUserProfileData({ anonymous }): Promise<UserProfile> {
    const { data } = await axiosInstance.get('/user/profile', { headers: { Anonymous: anonymous } });

    const categories: Category[] = data.categories.map((category: CategoryDto) => mapToCategories(category));
    const user: User = data.user && mapToUser(data.user);
    const division: Division = mapToDivision(data.division);
    const availableDivisions: DivisionSearchItem[] = data.availableDivisions.map((division: DivisionSearchItemDto) =>
      mapToSearchDivision(division),
    );
    const applicationSettings: ApplicationSettings = mapToBanner(data.applicationSettings);

    return { categories, user, division, availableDivisions, applicationSettings };
  },

  /**
   * Delete account.
   */
  async deleteAccount(): Promise<void> {
    await axiosInstance.delete('/user/me');
  },
};

/**
 * Map category link backend data to application data.
 *
 * @param categoryLinkDto Category link data from backend.
 */
const mapToCategoryLink = (categoryLinkDto: CategoryLinkDto): CategoryLink => {
  return new CategoryLink({ id: categoryLinkDto.id, name: categoryLinkDto.name, href: categoryLinkDto.url });
};

/**
 * Map category backend data to application data.
 *
 * @param categoryDto Category data from backend.
 */
const mapToCategories = (categoryDto: CategoryDto): Category => {
  const categoryLinks: CategoryLink[] = categoryDto.menuItems.map(item => mapToCategoryLink(item));

  return new Category({
    id: categoryDto.id,
    name: categoryDto.name,
    color: categoryDto.color,
    headerImageUrl: categoryDto.headerImageUrl,
    centralImageUrl: categoryDto.centralImageUrl,
    isAccessible: categoryDto.isAccessible,
    menuItems: categoryLinks,
  });
};

/**
 * User mapper function from DTO to model.
 *
 * @param userDto User DTO instance.
 */
const mapToUser = (userDto: UserDto): User => {
  return new User({
    id: userDto.id,
    email: userDto.email,
    alternativeEmail: userDto.alternativeEmail,
    firstName: userDto.firstName,
    lastName: userDto.lastName,
  });
};

/**
 * Division mapper function from DTO to model.
 *
 * @param divisionDto Division DTO instance.
 */
const mapToDivision = (divisionDto: DivisionDto): Division => {
  return new Division({
    id: divisionDto.id,
    name: divisionDto.name,
    serviceEmail: divisionDto.serviceEmail,
    servicePhone: divisionDto.servicePhone,
  });
};

/**
 * Division mapper function from DTO to model.
 *
 * @param divisionDto Division DTO instance.
 */
const mapToSearchDivision = (divisionDto: DivisionSearchItemDto): DivisionSearchItem => {
  return new DivisionSearchItem({
    id: divisionDto.id,
    name: divisionDto.name,
    isDefault: divisionDto.isDefault,
    isDisabled: divisionDto.isDisabled,
  });
};

/**
 * Application settings mapper function from DTO to model.
 *
 * @param applicationSettingsDto Application settings DTO instance.
 */
const mapToBanner = (applicationSettingsDto: ApplicationSettingsDto): ApplicationSettings => {
  return new ApplicationSettings({
    banner: {
      isBannerDisplayed: applicationSettingsDto.banner.isBannerDisplayed,
      bannerText: applicationSettingsDto.banner.bannerText,
    },
    isRequestAccessEnabled: applicationSettingsDto.isRequestAccessEnabled,
    isCCTFReceiptsIconDisplayed: applicationSettingsDto.displayCctfReceiptsIcon,
  });
};

export default UserProfileService;
