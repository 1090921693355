import { parseJwt } from '../helpers/parseJwt';

/** Service for manage user token. */
const TokenStorageService = {
  /**
   * Set token to storage.
   *
   * @param value Token value.
   */
  set(value: string): void {
    localStorage.setItem('token', value);
  },

  /** Get token from storage. */
  get(): string | null {
    return localStorage.getItem('token');
  },

  /** Remove token from storage. */
  remove(): void {
    localStorage.removeItem('token');
  },

  isExpired(token): boolean {
    const { exp } = parseJwt(token);

    if (Date.now() >= exp * 1000) {
      return true;
    }

    return false;
  },
};

export default TokenStorageService;
