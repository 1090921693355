import React from 'react';

/**
 * Active Alerts Icon component.
 *
 * @param props Props.
 * @param {Number} props.size Widget size.
 */
const ActiveAlertsIcon: React.FC<React.HTMLProps<HTMLDivElement>> = props => {
  return (
    <div {...props}>
      <img src="/assets/img/general/active-alerts-widget.png" alt="Active Alerts Icon" />
      <p className="text-uppercase">Track my order</p>
    </div>
  );
};

export default ActiveAlertsIcon;
