import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './style.scss';

import { BaseInput, BaseTextArea, BaseSelect } from '..';
import { QuestionLink, StateOption, User, ContactUsFormData } from '../../core/models';
import ContactUsQuestionLink from './ContactUsQuestionLink/ContactUsQuestionLink';
import { UserContext } from '../../App';
import SupportService from '../../core/services/SupportService';
import NotificationService from '../../core/services/NotificationService';
import StateService from '../../core/services/StateService';
import BaseButton from '../BaseButton/BaseButton';
import { REQUEST_ACCESS_MODAL_ID } from '../RequestAccessDialog/RequestAccessDialog';
import { formValidation } from './formSettings';

interface ContactUsProps {
  divisionPhone: string | null;
  isRequestAccessEnabled: boolean;
}

/**
 * Contact up root component.
 *
 * @param divisionPhone Division phone to call in "Customer Service".
 */
const ContactUs: React.FC<ContactUsProps> = ({ divisionPhone, isRequestAccessEnabled }) => {
  const userContext = useContext<User | null>(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formValidation) });
  const [username, setUsername] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);

  /** Set by default username and email state if it exists. */
  useEffect(() => {
    if (userContext) {
      setUsername(`${userContext.firstName} ${userContext.lastName}`);
      setUserEmail(userContext.email);
    }
  }, [userContext]);

  /** Disable button effect when for has validation errors. */
  const isDisabledButton = loading || Object.values(errors).length > 0;

  // Array of states without state abbrs
  const stateListOptions = StateService.getStates().map(state => ({
    label: state.value.toString(),
    value: state.value,
  }));

  const stateOptions: StateOption[] = [{ value: '', label: 'STATE', disabled: true }, ...stateListOptions];

  const questionLinks: QuestionLink[] = [
    {
      href: `tel:${divisionPhone}`,
      visible: Boolean(divisionPhone),
      imagePath: '/assets/img/svg/ic-phone.svg',
      label: 'Customer Service',
      secondaryLabel: divisionPhone,
    },
    {
      href: '/faq',
      reactLink: true,
      visible: true,
      imagePath: '/assets/img/svg/ic-web.svg',
      label: 'FAQ',
    },
    {
      href: REQUEST_ACCESS_MODAL_ID,
      visible: isRequestAccessEnabled,
      imagePath: '/assets/img/svg/ic-user.svg',
      label: 'Request Access',
    },
  ];

  const onSubmit = async (data, e): Promise<void> => {
    const { email, questionsAndNeeds, name, customerAccountNumber, state }: ContactUsFormData = data;

    try {
      setLoading(true);
      await SupportService.sendHelpMail({ email, questionsAndNeeds, name, customerAccountNumber, state });
      NotificationService.push('success', 'Success!', 'We will review your request soon.');
      e.target.reset();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        NotificationService.push('warning', 'Incorrect data.', 'Please enter correct data.');
      }
    }
    setLoading(false);
  };

  const renderQuestionLinks = questionLinks
    .filter(link => link.visible)
    .map((link: QuestionLink, index: number) => {
      return (
        <li key={index}>
          <ContactUsQuestionLink link={link} />
        </li>
      );
    });

  return (
    <div className="block-question">
      <div className="row">
        <div className="col-md-5">
          <div className="question-header">
            <h2 className="color-green text-uppercase">Have Questions?</h2>
            <h4 className="color-green text-uppercase font-normal">How can we help?</h4>
          </div>
          <nav className="question-links">
            <ul>{renderQuestionLinks}</ul>
          </nav>
        </div>
        <div className="col-md-7">
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <BaseInput
              {...register('name')}
              type="text"
              placeholder="Name"
              name="name"
              defaultValue={username}
              invalid={errors.name}
            />
            <BaseInput
              {...register('email')}
              type="email"
              placeholder="Email"
              name="email"
              defaultValue={userEmail}
              invalid={errors.email}
            />
            <BaseInput
              {...register('customerAccountNumber')}
              type="text"
              placeholder="Customer Account Number"
              name="customerAccountNumber"
              invalid={errors.customerAccountNumber}
            />

            <BaseSelect {...register('state')} options={stateOptions} name="state" invalid={errors.state} />

            <BaseTextArea
              {...register('questionsAndNeeds')}
              rows={5}
              placeholder="How Can We Help?"
              name="questionsAndNeeds"
              invalid={errors.questionsAndNeeds}
            />
            <BaseButton
              loading={loading}
              className="btn btn-orange btn-lg btn-block"
              type="submit"
              disabled={isDisabledButton}
            >
              Submit
            </BaseButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
