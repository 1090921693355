import React from 'react';
import { TableColumn, TableComponent } from '../../../components/Table/Table';
import { Pickup } from '../../../core/models/Pickup';

const columns: TableColumn<Pickup>[] = [
  {
    columnName: 'Pick Up No',
    key: 'no',
  },
  {
    columnName: 'Pick SKU',
    key: 'sku',
  },
  {
    columnName: 'Pick Description',
    key: 'description',
    width: '240px',
  },
  {
    columnName: 'Credit UPC 1',
    key: 'upc1',
  },
  {
    columnName: 'Credit UPC 2',
    key: 'upc2',
  },
  {
    columnName: 'Qty to Pick',
    key: 'qtyToPick',
  },
  {
    columnName: 'Qty Picked',
    key: 'qtyPicket',
  },
  {
    columnName: 'Pick Reason',
    key: 'reason',
  },
];

type Props = {
  isLoading: boolean;
  data: Pickup[];
};

export const DriverPickupsTable: React.FC<Props> = ({ isLoading, data }) => {
  return (
    <div>
      <h2>Pickups</h2>
      <TableComponent isLoading={isLoading} data={data} columns={columns} />
    </div>
  );
};
