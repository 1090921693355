import React, { useEffect } from 'react';
import cn from 'classnames';
import useMedia from 'use-media';

import './style.scss';

import { RoadnetActiveAlertService } from '../../core/services/RoadnetActiveAlertService';
import Backdrop from '../Backdrop/Backdrop';

interface ActiveAlertsWidgetProps {
  opened: boolean;
  handleClose: () => void;
}

/**
 * Active Alerts Widget component.
 *
 * @param props Props.
 * @param {Boolean} props.opened Opened widget state.
 * @param {Function} props.handleClose Close widget handler function.
 */
const ActiveAlertsWidget: React.FC<ActiveAlertsWidgetProps> = props => {
  const { opened, handleClose } = props;
  const wrapperClassName: string = cn('widget-wrapper', { 'widget-wrapper--show': opened });
  const widgetClassNames: string = cn({ show: opened });
  const isMobileWidth: boolean = useMedia({ maxWidth: 1199 });

  /** Deploy widget on mount effect. */
  useEffect(() => {
    RoadnetActiveAlertService.deployWidget();
  }, [isMobileWidth]);

  const renderWidget = (
    <>
      <div className="widget-wrapper__close-button" onClick={handleClose}>
        x
      </div>
      <div className={widgetClassNames} id="RAAWebApp" />
    </>
  );

  const backdroppedWidget = isMobileWidth ? <Backdrop>{renderWidget}</Backdrop> : renderWidget;

  return <div className={wrapperClassName}>{backdroppedWidget}</div>;
};

export default ActiveAlertsWidget;
