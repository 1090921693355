/** PDI Login model. */
export class PDILogin {
  /** Id. */
  public id: number;

  /** First name. */
  public firstName: string;

  /** Last name. */
  public lastName: string;

  /** User name. */
  public username: string;

  /** JWT token. */
  public jwtToken: string;

  /** Refresh token. */
  public refreshToken: string;

  /**
   * PDI login model constructor.
   *
   * @param data PDI data.
   */
  public constructor(data: PDILogin) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.username = data.username;
    this.jwtToken = data.jwtToken;
    this.refreshToken = data.refreshToken;
  }
}
