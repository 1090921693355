import React, { FC } from 'react';
import { ShippingModal, shippingModalId } from './ShippingModal/ShippingModal';
import './style.scss';

export const ShippingButton: FC = () => {
  return (
    <div className="shippingButtonWrapper">
      <button
        className="btn btn-white btn-lg icon shippingButton"
        type="button"
        data-toggle="modal"
        data-target={`#${shippingModalId}`}
      >
        <img src="/assets/img/svg/ic-receipt.svg" className="shippingButtonImage" alt="Instagram logo" />
      </button>
      <span className="shippingText">CCTF Delivery E-Receipts</span>
      <ShippingModal />
    </div>
  );
};
