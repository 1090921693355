import * as Yup from 'yup';
import 'yup-phone';
import { initialUserState } from '../shared/initialUserState';

export const initialFormState = {
  user: [initialUserState],
  scanData: false,
  backOffice: false,
  topOffRewards: false,
  pdiSolutionToEnrollIn: [],
  cseNumber: '',
  salesRepresentativeFullName: '',
  salesRepresentativeEmail: '',
};

export const formValidation = Yup.object().shape({
  scanData: Yup.boolean(),
  backOffice: Yup.boolean(),
  topOffRewards: Yup.boolean().notRequired(),
  salesRepresentativeFullName: Yup.string().required('Required'),
  salesRepresentativeEmail: Yup.string()
    .email('Must be a valid email.')
    .required('Required'),
  user: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string()
          .required('Required')
          .trim(),
        lastName: Yup.string()
          .required('Required')
          .trim(),
        email: Yup.string()
          .email('Must be a valid email.')
          .required('Required'),
        phone: Yup.string()
          .phone('US', true, 'Must be a valid phone number')
          .required('Required'),
        storeName: Yup.string()
          .required('Required')
          .trim(),
        street: Yup.string()
          .required('Required')
          .trim(),
        city: Yup.string()
          .required('Required')
          .trim(),
        state: Yup.string()
          .required('Required')
          .trim(),
        zip: Yup.string()
          .required('Required')
          .trim(),
        plan: Yup.string()
          .nullable()
          .test('top-off-rewards', 'Required', (value, context) => {
            const topOffRewards = (context.options as any).from[1].value.topOffRewards;

            if (value && !topOffRewards) {
              return true;
            }

            return topOffRewards;
          }),
        accountNumber: Yup.string()
          .required('Required')
          .trim(),
        brandMetaData: Yup.object().shape({
          coreMarkDivisionName: Yup.array().min(1, 'Required'),
        }),
        posSystem: Yup.string()
          .required('Required')
          .trim(),
      }),
    )
    .required('Required'),
});
