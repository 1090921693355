import React from 'react';
import { Switch, Route } from 'react-router-dom';

import IndexPage from './pages/IndexPage/IndexPage';
import AuthPage from './pages/AuthPage/AuthPage';
import FAQPage from './pages/FAQPage/FAQPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AppUrlListener from './components/AppUrlListener';
import { ShippingPage } from './pages/ShippingPage/ShippingPage';
import { ShippingDetailPage } from './pages/ShippingPage/ShippingDetailPage/ShippingDetailPage';

const AppRouter: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <AppUrlListener />
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/cse-form" component={IndexPage} />
        <Route exact path="/index" component={IndexPage} />
        <Route exact path="/faq" component={FAQPage} />
        <Route exact path="/auth" component={AuthPage} />
        <Route exact path="/shipping" component={ShippingPage} />
        <Route exact path="/shipping/:code" component={ShippingDetailPage} />
      </Switch>
    </>
  );
};

export default AppRouter;
