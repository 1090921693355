import React from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form/dist/types';

interface BaseTextAreaProps {
  name: string;
  rows: number;
  placeholder: string;
  invalid?: FieldError | any;
  ref?: React.Ref<HTMLTextAreaElement>;
}

/** Base textarea component. */
const BaseTextArea: React.FC<BaseTextAreaProps> = React.forwardRef((props, ref) => {
  const { invalid } = props;
  const textAreaClassName = classNames('form-control', { invalid });

  return (
    <div className="form-group">
      <textarea className={textAreaClassName} {...props} ref={ref} />
    </div>
  );
});

BaseTextArea.displayName = 'BaseTextArea';

export default BaseTextArea;
