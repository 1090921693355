import { saveAs } from 'file-saver';

/**
 * Save blob response to file.
 * @param blob Blob to save.
 * @param fileName File name.
 */
export function downloadFile(blob: Blob, fileName: string): void {
  saveAs(blob, `${fileName}.pdf`);
}
