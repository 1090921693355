import { useState, useEffect } from 'react';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio';

/**
 * Custom hook for biometry check.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useBiometry() {
  const [isBiometryAvailable, setIsBiometryAvailable] = useState<boolean>(false);
  const [availableBiometryType, setAvailableBiometryType] = useState<string | null>(null);

  useEffect(() => {
    FingerprintAIO.isAvailable().then(result => {
      setIsBiometryAvailable(true);
      setAvailableBiometryType(result);
    });

    return (): void => {
      setIsBiometryAvailable(false);
      setAvailableBiometryType(null);
    };
  }, []);

  return { isBiometryAvailable, availableBiometryType };
}
