import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

import AppLayout from '../../containers/AppLayout/AppLayout';
import ChevronLeftIcon from '../../components/icons/ChevronLeftIcon';

/** FAQ page component. */
const FAQPage: React.FC = () => {
  return (
    <AppLayout>
      <div className="faq-container">
        <h1 className="text-center">
          <Link to="/" className="back-link">
            <ChevronLeftIcon /> BACK
          </Link>
          FAQ
        </h1>
        <h3>Q1: What is CORE-SOURCE?</h3>
        <p>
          A1: Core-Mark&apos;s CORE-SOURCE is a &quot;one-stop shop&quot;, streamlined data solution for
          Core-Mark&apos;s internal team and external customers. Core-Mark employees and retailers will have access to a
          customized portal with all necessary tools to help support a successful partnership with Core-Mark.
        </p>
        <h3>Q2: What should I do if I&apos;ve forgotten my Log In Password?</h3>
        <p>
          A2: Select the &apos;Sign On&apos; button and choose the &apos;Forgot Password&apos; link. Enter your email
          address to receive instructions on how to reset your password.
        </p>
        <h3>Q3: How do I request access to CORE-SOURCE?</h3>
        <p>
          A3: Select the &apos;Request Access&apos; button. An email will automatically be generated. Fill out the
          requested information and send.
        </p>
        <h3>
          Q4: What should I do if I have a question about a specific menu option (tile) or one of its sub-menu (links)?
        </h3>
        <p>A4: You have the following two options.</p>
        <ul>
          <li> 1) Call the Customer Service # located under the menu option tiles.</li>
          <li> 2) Fill out the template located under the menu option tiles and hit &apos;Submit&apos;.</li>
        </ul>
        <h3>
          Q5: When using the &apos;Track My Order&apos; widget, what is my &apos;Location ID(s)&apos; and how do I enter
          this information?
        </h3>
        <p>
          A5: Your &apos;Location ID(s)&apos; is your 3 digit Division # (i.e 065) and your 6 digit Customer #. Do not
          include spaces between your Division # and Customer#. If you need help finding this information, please
          contact Customer Service. For desktop users, the Customer Service # is located in the bottom left-hand side of
          your screen. For mobile device users, the Customer Service # is located underneath the &apos;Have
          Questions?&apos; section toward the bottom of the screen.
        </p>
        <Link to="/" className="btn btn-orange back-button">
          BACK
        </Link>
      </div>
    </AppLayout>
  );
};

export default FAQPage;
