import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../App';
import { useSearchParam } from '../../../core/hooks/useSearchParam';
import { StateOption, User } from '../../../core/models';
import CustomerService from '../../../core/services/CustomerService';
import BaseButton from '../../BaseButton/BaseButton';
import BaseSelect from '../../BaseSelect/BaseSelect';
import { DateRangePicker } from '../../DateRangePicker/DateRangePicker';
import LogoWhite from '../../Footer/FooterLogo/LogoWhite';
import { FormItem } from '../../FormItem/FormItem';
import './style.scss';

type ShippingModalForm = {
  readonly customer: string;
  readonly dateRange: Date[] | null;
};

export const shippingModalId = 'shippingModalId';

const LABEL_OPTION: StateOption = { value: '', label: 'CUSTOMER', disabled: true };

const SHIPPING_MODAL_DEFAULT_VALUES: ShippingModalForm = {
  customer: '',
  dateRange: null,
};

export const ShippingModal: FC = () => {
  const searchParams = useSearchParam();

  const customerNo = searchParams.get('customer-no');
  const startDate = searchParams.get('start-date');
  const endDate = searchParams.get('end-date');

  const initialDateRange = startDate != null && endDate != null ? [new Date(startDate), new Date(endDate)] : null;

  const { control, handleSubmit, clearErrors, reset } = useForm<ShippingModalForm>({
    defaultValues: SHIPPING_MODAL_DEFAULT_VALUES,
  });

  const history = useHistory();

  const user: User | null = useContext(UserContext);
  const [customers, setCustomers] = useState<StateOption[]>([LABEL_OPTION]);

  const closeShippingModal = (): void => {
    $(`#${shippingModalId}`).modal('hide');
  };

  /** Form submit handler. */
  const handleFormSubmit = async (data: ShippingModalForm): Promise<void> => {
    closeShippingModal();
    const startDate = data.dateRange[0].toDateString();
    const endDate = data.dateRange[1].toDateString();
    searchParams.set('customer-no', data.customer);
    searchParams.set('start-date', moment(startDate).format('MM/DD/YYYY'));
    searchParams.set('end-date', moment(endDate).format('MM/DD/YYYY'));
    history.push({ pathname: '/shipping', search: searchParams.toString() });
  };

  const getCustomers = async (): Promise<void> => {
    const customerList = await CustomerService.getCustomersByCoreMarkId(user.id);
    const options: StateOption[] = customerList.map(customer => ({ value: customer.number, label: customer.name }));
    setCustomers([LABEL_OPTION, ...options]);
  };

  const openModalOnFirstLoad = async (): Promise<void> => {
    const signOnModal = $(`#${shippingModalId}`);
    if (customerNo != null || (startDate != null && endDate != null)) {
      await getCustomers();
      signOnModal.modal('show');
    }
  };

  useEffect(() => {
    openModalOnFirstLoad();
    reset({ dateRange: initialDateRange, customer: customerNo });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const signOnModal = $(`#${shippingModalId}`);

    signOnModal.on('hidden.bs.modal', function() {
      clearErrors();
      reset();
    });

    signOnModal.on('show.bs.modal', function() {
      getCustomers();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, reset]);

  return (
    <div
      className="modal fade"
      id={shippingModalId}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="floatingModalLabel"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
              <span className="shippingModalCloseButton" aria-hidden="true">
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="logo-container">
              <LogoWhite />
            </div>
            <div className="text-center text-white">
              <h4 className="auth-form-title" id="shippingModalLabel">
                Choose Customer Name & Date Range
              </h4>
              <hr className="separator" />
            </div>

            <form onSubmit={handleSubmit(handleFormSubmit)} className="shippingModalBody">
              <Controller
                control={control}
                name="customer"
                rules={{ required: 'This field is required' }}
                render={({ field: { value, onChange }, fieldState: { error } }): React.JSX.Element => {
                  return (
                    <FormItem error={error?.message} label="Customer">
                      <BaseSelect onChangeForController={onChange} value={value} options={customers} name="customer" />
                    </FormItem>
                  );
                }}
              />
              <Controller
                control={control}
                name="dateRange"
                rules={{ required: 'This field is required' }}
                render={({ field: { value, onChange }, fieldState: { error } }): React.JSX.Element => {
                  return (
                    <FormItem error={error?.message} label="Date range">
                      <DateRangePicker placeholder="Date range" value={value} onChange={onChange} />
                    </FormItem>
                  );
                }}
              />
              <BaseButton type="submit" className="btn btn-orange btn-lg shippingModal">
                Go
              </BaseButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
