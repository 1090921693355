import moment from 'moment';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import BaseInput from '../BaseInput/BaseInput';
import './style.scss';

type Props = {
  readonly placeholder?: string;
  readonly value: Date[] | null;
  readonly onChange: (value: Date[] | null) => void;
  readonly format?: string;
};

const DateRangePickerComponent: FC<Props> = ({ placeholder, value, format = 'MM/DD/YYYY', onChange }) => {
  const dateRange = 'dateRange';
  const dateRangeId = '#dateRange';

  const readableValueAsString = useMemo(() => {
    const startDate = value?.at(0);
    const endDate = value?.at(1);

    if (startDate == null || endDate == null) {
      return '';
    }

    const startDateAsString = moment(startDate).format(format);
    const endDateAsString = moment(endDate).format(format);
    return `${startDateAsString} - ${endDateAsString}`;

    // Get the value on first load, no need to applied the change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const resetDateRangePicker = useCallback(() => {
    $(dateRangeId).val('');
    onChange(null);
  }, [onChange]);

  const adjustMaxHeightForDatePicker = (): void => {
    const element = document.querySelectorAll<HTMLElement>('.daterangepicker');
    const lastElement = element[element.length - 1];
    const browserHeight = window.innerHeight;
    const topPosition = lastElement.getBoundingClientRect().top;
    const paddingBottom = 20;
    lastElement.style.maxHeight = `${browserHeight - topPosition - paddingBottom}px`;
  };

  const cleanUpdateDatePickerOnClose = (): void => {
    const element = document.querySelectorAll<HTMLElement>('.daterangepicker');
    if (element.length > 1) {
      element[0].remove();
    }
  };

  useEffect(() => {
    resetDateRangePicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(dateRangeId).val(readableValueAsString);
    $(function() {
      // Date range picker is imported by CDN, there is no type applied.
      // @ts-expect-error
      $(dateRangeId).daterangepicker(
        {
          opens: 'left',
          autoUpdateInput: false,
          showDropdowns: true,
          locale: {
            cancelLabel: 'Clear',
          },
        },
        function(start: moment.Moment, end: moment.Moment) {
          onChange([start.toDate(), end.toDate()]);
        },
      );

      $(dateRangeId).on('apply.daterangepicker', function(_event, picker) {
        $(this).val(picker.startDate.format(format) + ' - ' + picker.endDate.format(format));
      });

      $(dateRangeId).on('show.daterangepicker', function(_event, picker) {
        adjustMaxHeightForDatePicker();
      });

      $(dateRangeId).on('hide.daterangepicker', function(_event, picker) {
        cleanUpdateDatePickerOnClose();
      });

      $(dateRangeId).on('cancel.daterangepicker', function() {
        $(this).val('');
        onChange(null);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, resetDateRangePicker, format, readableValueAsString]);

  return (
    <div className="dateRange">
      <BaseInput placeholder={placeholder} id={dateRange} type="text" name="daterange" readOnly autoComplete="off" />
      <button className="close dateRangeClearButton" type="button" aria-label="Close" onClick={resetDateRangePicker}>
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
};

export const DateRangePicker = memo(DateRangePickerComponent);
