import React, { ReactElement } from 'react';
import classNames from 'classnames';

const AuthModalValidationError = (props): ReactElement => {
  const { children, classes } = props;

  const errorClassnames = classNames('error-msg', classes);

  return (
    <div className={errorClassnames}>
      <b>{children}</b>
    </div>
  );
};

export default AuthModalValidationError;
