import React, { ReactElement } from 'react';
import { Roles } from '../RequestAccessDialog';
import TextareaAutosize from 'react-textarea-autosize';

interface Props {
  role: Roles;
  handleFieldChange(event): void;
  customerAccountName: string;
  customerAccountNumber: string;
  coreMarkEmployeeRole: string;
  chainName: string;
  chainNumber: string;
}

export const RoleFields: React.FC<Props> = ({
  role,
  handleFieldChange,
  customerAccountName,
  customerAccountNumber,
  coreMarkEmployeeRole,
  chainName,
  chainNumber,
}) => {
  /** Function that returns different templates depending on role */
  function renderSwitch(role: Roles): ReactElement {
    switch (role) {
      case 'Employee':
        return (
          <label>
            <span className="required-mark">*</span>Core-Mark Employee Role:
            <input
              value={coreMarkEmployeeRole}
              onChange={handleFieldChange}
              name="coreMarkEmployeeRole"
              className="line-input"
              type="text"
            />
          </label>
        );

      case 'Customer':
        return (
          <>
            <label className="no-bottom-margin">
              <span className="required-mark">*</span>Customer Account Names:
              <small>i.e. Bob’s Shop 1, Bob’s Shop 2</small>
            </label>
            <div className="textarea-field">
              <TextareaAutosize
                maxLength={255}
                value={customerAccountName}
                onChange={handleFieldChange}
                name="customerAccountName"
                className="line-input"
              />
            </div>

            <label className="no-bottom-margin">
              <span className="required-mark">*</span>Customer Account #s:
              <small>i.e. 065123456, 065789123</small>
            </label>
            <div className="textarea-field">
              <TextareaAutosize
                maxLength={255}
                value={customerAccountNumber}
                onChange={handleFieldChange}
                name="customerAccountNumber"
                className="line-input"
              />
            </div>
          </>
        );

      case 'ChainHeadquartersAccount':
        return (
          <>
            <label htmlFor="chainName">
              <span className="required-mark">*</span>Chain Name:
              <small>i.e. Bob’s Chain</small>
            </label>
            <div className="full-width-input">
              <input
                value={chainName}
                onChange={handleFieldChange}
                name="chainName"
                id="chainName"
                className="line-input"
                type="text"
              />
            </div>

            <label className="" htmlFor="chainNumber">
              Core-Mark Chain #:
              <small>i.e. 1234, 789</small>
            </label>
            <div className="full-width-input">
              <input
                value={chainNumber}
                onChange={handleFieldChange}
                name="chainNumber"
                id="chainNumber"
                className="line-input"
                type="text"
              />
            </div>
          </>
        );
    }
  }
  return <>{renderSwitch(role)}</>;
};
