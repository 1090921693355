export class Credit {
  /** SKU. */
  public sku: string | null;

  /** Description. */
  public description: string | null;

  /** UPC1 code. */
  public upc1: string | null;

  /** UPC2 code. */
  public upc2: string | null;

  /** Quantity credited. */
  public qtyCredited: number | null;

  /** Credit container. */
  public container: string | null;

  /** Credit invoice. */
  public invoice: string | null;

  /** Credit reason. */
  public reason: string | null;

  public constructor(data: Credit) {
    this.sku = data.sku;
    this.description = data.description;
    this.upc1 = data.upc1;
    this.upc2 = data.upc2;
    this.qtyCredited = data.qtyCredited;
    this.container = data.container;
    this.invoice = data.invoice;
    this.reason = data.reason;
  }
}
