import React from 'react';

import './style.scss';

import HeaderLink from './HeaderLink/HeaderLink';

/** Header links component. */
const HeaderLinks: React.FC = () => {
  return (
    <div className="navbar-collapse collapse" id="navbar">
      <ul className="nav navbar-nav">
        <HeaderLink href="https://www.core-mark.com/About/About-Us" text="About" icon="/assets/img/svg/ic-about.svg" />
        <HeaderLink
          href="https://investors.pfgc.com/overview/default.aspx"
          text="Investors"
          icon="/assets/img/svg/ic-investors.svg"
        />
        <HeaderLink
          href="https://careers.core-mark.com/working-here/"
          text="Careers"
          icon="/assets/img/svg/ic-careers.svg"
        />
        <HeaderLink
          href="https://www.core-mark.com/Contact/Get-In-Touch"
          text="Contact"
          icon="/assets/img/svg/ic-contact.svg"
        />
        <HeaderLink
          href="https://www.core-mark.com/Programs/Foodservice-Programs"
          text="Foodservice Programs"
          icon="/assets/img/svg/ic-programs.svg"
        />
        <HeaderLink
          href="https://www.core-mark.com/Solutions/Retailer-Solutions"
          text="Retailer Solutions"
          icon="/assets/img/svg/ic-solutions.svg"
        />
        <HeaderLink
          href="https://www.imarkportal.com/ecmvc/portal/auth/login"
          text="Customer Logins"
          icon="/assets/img/svg/ic-user.svg"
        />
      </ul>
    </div>
  );
};

export default HeaderLinks;
