import React from 'react';
import Loader from 'react-loader-spinner';
import cn from 'classnames';

import './style.scss';

interface DataLoaderProps extends React.HTMLProps<HTMLDivElement> {
  color: string;
  size: number;
  type?:
    | 'BallTriangle'
    | 'Audio'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'Oval'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots';
}

const DataLoader: React.FC<DataLoaderProps> = props => {
  const { color, size, type, className } = props;
  const loaderClassName = cn('data-loader', className);

  return <Loader className={loaderClassName} type={type} color={color} height={size} width={size} />;
};

DataLoader.defaultProps = {
  type: 'BallTriangle',
};

export default DataLoader;
