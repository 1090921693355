import { isPlatform } from '@ionic/react';

/**
 * Native platforms service.
 */
const PlatformService = {
  isIOS(): boolean {
    return isPlatform('ios') && !isPlatform('mobileweb');
  },

  isAndroid(): boolean {
    return isPlatform('android') && !isPlatform('mobileweb');
  },

  isMobile(): boolean {
    return isPlatform('android') || isPlatform('ios');
  },

  isWebAndroid(): boolean {
    return isPlatform('android');
  },
};

export default PlatformService;
