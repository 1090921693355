import React, { useEffect, useCallback, useState } from 'react';

import BaseButton from '../../BaseButton/BaseButton';
import './style.scss';

/**
 * Step 2 form component.
 */
export default function Step2({ docuSignUrl, initialValues, title, submitting, error, onSubmit }): JSX.Element {
  const [isDocumentSigned, setDocumentSigned] = useState(false);

  const handleFormSubmit = (): void => {
    onSubmit(initialValues);
  };

  const checkDocumentSigned = useCallback((event: MessageEvent) => {
    if (typeof event.data === 'string' && event.data === 'signed') {
      setDocumentSigned(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', checkDocumentSigned);

    return (): void => {
      window.removeEventListener('message', checkDocumentSigned);
    };
  }, [checkDocumentSigned]);

  const isDisabledSubmit = !isDocumentSigned || submitting;

  return (
    <>
      <h2 className="text-center">{title}</h2>
      <div className="cse-step2">
        <iframe src={docuSignUrl} title="DocuSign" className="docusign-document" referrerPolicy="no-referrer" />

        <div className="row">
          <div className="col-md-12">
            <span className="error-message">{error}</span>
          </div>
        </div>

        <BaseButton
          className="btn btn-orange btn-submit btn-lg btn-block"
          type="button"
          onClick={handleFormSubmit}
          disabled={isDisabledSubmit}
          loading={submitting}
        >
          Next
        </BaseButton>
      </div>
    </>
  );
}
