import React, { useState, ReactElement } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';

import './../style.scss';

import BaseButton from '../../BaseButton/BaseButton';
import Tooltip from '../../Tooltip/Tooltip';
import StateService from '../../../core/services/StateService';
import { preventNonNumericValues } from '../../../core/helpers/preventNonNumericValues';
import { StateOption } from '../../../core/models';
import { posOptions } from '../shared/pos';

interface UserDetailsProps {
  hasPasswordFields?: boolean;
  availableDivisions: any[];
  onEnrollAnotherStore?: () => void;
  onRemoveEnrollAnotherStore?: (index: number) => void;
  data: any[];
  serverErrors?: { index: number; message: string };
}

/**
 * UserDetails component.
 */
export default function UserDetails({
  hasPasswordFields,
  availableDivisions,
  onEnrollAnotherStore,
  onRemoveEnrollAnotherStore,
  data,
  serverErrors,
}: UserDetailsProps): JSX.Element {
  const {
    control,
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const values = getValues();

  const stateOptions = StateService.getStates().map(state => ({ value: state.label, label: state.label }));
  const divisionOptions = availableDivisions.map(division => ({ value: division.id, label: division.name }));

  const handleDivisionChange = (options: StateOption[], onChange): void => {
    const divisionNumbers = [...options.map(elem => elem.value)];

    onChange(divisionNumbers);
  };

  const handleSelectChange = (option: StateOption, onChange): void => {
    if (option) {
      onChange(option.value);
    }
  };

  const handleEnrollAnotherStoreClick = (): void => {
    onEnrollAnotherStore();
  };

  const getDivisionValues = (user): any[] => {
    return user.brandMetaData.coreMarkDivisionName?.map((divisionId: number) => {
      return divisionOptions.find(division => division.value === divisionId);
    });
  };

  const handleConfirmCancel = (): void => {
    setConfirmVisible(false);
  };

  const handleConfirmRemove = (index: number): void => {
    setConfirmVisible(false);
    onRemoveEnrollAnotherStore(index);
  };

  return (
    <>
      {data?.map((item, index) => {
        return (
          <div key={index} className={classNames('user-details-wrapper', { first: index === 0 })}>
            {index > 0 && (
              <>
                <button
                  onClick={(): void => setConfirmVisible(true)}
                  className="user-details-remove close"
                  type="button"
                >
                  x
                </button>
                {isConfirmVisible && (
                  <div className="confirm-user-details-remove">
                    <button className="btn btn-sm btn-link" onClick={handleConfirmCancel} type="button">
                      Cancel
                    </button>
                    <button className="btn btn-sm" onClick={(): void => handleConfirmRemove(index)} type="button">
                      Confirm remove
                    </button>
                  </div>
                )}
              </>
            )}
            {index === 0 && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-divider">
                      <span className="text">Sales Representative Information</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <span className="required-mark">*</span>Full Name:
                      <input
                        {...register('salesRepresentativeFullName')}
                        className={classNames('line-input', {
                          invalid: errors?.salesRepresentativeFullName,
                        })}
                      />
                    </label>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <span className="required-mark">*</span>Email Address:
                      <input
                        {...register('salesRepresentativeEmail')}
                        className={classNames('line-input', { invalid: errors?.salesRepresentativeEmail })}
                        type="email"
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-divider">
                      <span className="text">Customer Information</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <span className="required-mark">*</span>First Name:
                      <input
                        {...register(`user.${index}.firstName`)}
                        className={classNames('line-input', {
                          invalid: errors?.user && errors?.user[index]?.firstName,
                        })}
                      />
                    </label>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <span className="required-mark">*</span>Last Name:
                      <input
                        {...register(`user.${index}.lastName`)}
                        className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.lastName })}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <span className="required-mark">*</span>Phone:
                      <input
                        {...register(`user.${index}.phone`)}
                        className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.phone })}
                        type="tel"
                        maxLength={10}
                        onKeyDown={preventNonNumericValues}
                      />
                    </label>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <span className="required-mark">*</span>Email Address:
                      <input
                        {...register(`user.${index}.email`)}
                        className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.email })}
                        type="email"
                      />
                    </label>
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-md-12">
                <div className="text-divider">
                  <span className="text">Store Details</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>
                  <span className="required-mark">*</span>Store Name:
                  <input
                    {...register(`user.${index}.storeName`)}
                    className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.storeName })}
                  />
                </label>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>
                  <span className="required-mark">*</span>Address:
                  <input
                    {...register(`user.${index}.street`)}
                    className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.street })}
                  />
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>
                  <span className="required-mark">*</span>City:
                  <input
                    {...register(`user.${index}.city`)}
                    className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.city })}
                  />
                </label>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="select-field">
                  <label>
                    <span className="required-mark">*</span>State
                  </label>
                  <Controller
                    control={control}
                    name={`user.${index}.state`}
                    render={({ field: { onChange } }): ReactElement => (
                      <Select
                        defaultValue={
                          stateOptions[stateOptions.findIndex(option => option.value === values.user[index].state)]
                        }
                        options={stateOptions}
                        onChange={(value: StateOption): void => handleSelectChange(value, onChange)}
                        className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.state })}
                        classNamePrefix="select"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>
                  <span className="required-mark">*</span>Zip Code:
                  <input
                    {...register(`user.${index}.zip`)}
                    className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.zip })}
                  />
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>
                  <span className="required-mark">*</span>Store Core-Mark Account Number:
                  <input
                    {...register(`user.${index}.accountNumber`)}
                    className={classNames('line-input', {
                      invalid: errors?.user && errors?.user[index]?.accountNumber,
                    })}
                    inputMode="numeric"
                  />
                </label>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="select-field">
                  <label>
                    <span className="required-mark">*</span>Division Name
                  </label>
                  <Controller
                    control={control}
                    name={`user.${index}.brandMetaData.coreMarkDivisionName`}
                    render={({ field: { onChange } }): ReactElement => (
                      <Select
                        isMulti
                        defaultValue={getDivisionValues(values.user[index])}
                        hideSelectedOptions={false}
                        options={divisionOptions}
                        onChange={(value: StateOption[]): void => handleDivisionChange(value, onChange)}
                        className={classNames('line-input', {
                          invalid: errors?.user && errors?.user[index]?.brandMetaData?.coreMarkDivisionName,
                        })}
                        classNamePrefix="select"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="select-field">
                  <label>
                    <span className="required-mark">*</span>What POS system do you have?
                  </label>
                  <Controller
                    control={control}
                    name={`user.${index}.posSystem`}
                    render={({ field: { onChange } }): ReactElement => (
                      <Select
                        defaultValue={
                          posOptions[posOptions.findIndex(option => option.value === values.user[index].posSystem)]
                        }
                        options={posOptions}
                        onChange={(value: StateOption): void => handleSelectChange(value, onChange)}
                        className={classNames('line-input', {
                          invalid: errors?.user && errors?.user[index]?.posSystem,
                        })}
                        classNamePrefix="select"
                      />
                    )}
                  />
                </div>
                {values.user[index].posSystem === posOptions[3].value && (
                  <Tooltip>Select a different POS system in order to continue</Tooltip>
                )}
              </div>
            </div>

            {hasPasswordFields && (
              <div className="row">
                <div className="col-xs-12">
                  <h6 className="mb0">
                    <em>Please create a username and password for PDI&apos;s CStore Essentials!</em>
                  </h6>
                  <Tooltip alwaysShow>
                    NOTE: Password must be at least 8 characters long, contain at least 1 upper case letter, 1 lower
                    case letter, 1 number character, 1 symbol character.
                  </Tooltip>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="select-field two-lines">
                    <label>
                      <span className="required-mark">*</span>Username
                    </label>
                    <input
                      {...register(`user.${index}.userName`)}
                      className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.userName })}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="select-field two-lines">
                    <label>
                      <span className="required-mark">*</span>Password
                    </label>
                    <input
                      {...register(`user.${index}.password`)}
                      className={classNames('line-input', { invalid: errors?.user && errors?.user[index]?.password })}
                      type="password"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="select-field two-lines">
                    <label>
                      <span className="required-mark">*</span>Confirm Password
                    </label>
                    <input
                      {...register(`user.${index}.confirmPassword`)}
                      className={classNames('line-input', {
                        invalid: errors?.user && errors?.user[index]?.confirmPassword,
                      })}
                      type="password"
                    />
                  </div>
                </div>
              </div>
            )}

            {serverErrors && serverErrors.index === index && (
              <div className="row">
                <div className="col-md-12">
                  <span className="error-message">{serverErrors.message}</span>
                </div>
              </div>
            )}
          </div>
        );
      })}

      {onEnrollAnotherStore && (
        <div className="row">
          <div className="col-md-12 add-enroll">
            <BaseButton className="btn btn-link btn-sm" type="button" onClick={handleEnrollAnotherStoreClick}>
              Enroll another store
            </BaseButton>
          </div>
        </div>
      )}
    </>
  );
}

UserDetails.defaultProps = {
  hasPasswordFields: false,
};
