export const posOptions = [
  {
    value: 'NCR',
    label: 'NCR',
  },
  {
    value: 'Gilbarco',
    label: 'Gilbarco',
  },
  {
    value: 'Verifone',
    label: 'Verifone',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
