import { Credit } from './Credit';
import { Dlvitm } from './Dlvitm';
import { Pickup } from './Pickup';
import { Tote } from './Tote';

/** Core Mark Shipment detail. */
export class CoreMarkShipmentDetail {
  /** Ship number. */
  public shipNo: string | null;

  /** Stage number. */
  public stageNo: string | null;

  /** Route. */
  public route: string | null;

  /** Delivery date. */
  public deliveryDate: Date | null;

  /** Accepted by. */
  public acceptedBy: string | null;

  /** Pieces delivered. */
  public piecesDelivered: string | null;

  /** Items credited. */
  public itemsCredited: string | null;

  /** Pieces shorted. */
  public piecesShorted: string | null;

  /** Empty containers returned. */
  public emptyContainersReturned: string | null;

  /** Driver pickups. */
  public driverPickups: string | null;

  /** Freezer temperature. */
  public freezerTemp: string | null;

  /** Cooler temperature. */
  public coolerTemp: string | null;

  public dlvitms: Dlvitm[];
  public crdts: Credit[];
  public pickups: Pickup[];
  public totes: Tote[];

  public constructor(data: CoreMarkShipmentDetail) {
    this.shipNo = data.shipNo;
    this.stageNo = data.stageNo;
    this.route = data.route;
    this.deliveryDate = data.deliveryDate;
    this.acceptedBy = data.acceptedBy;
    this.piecesDelivered = data.piecesDelivered;
    this.itemsCredited = data.itemsCredited;
    this.piecesShorted = data.piecesShorted;
    this.emptyContainersReturned = data.emptyContainersReturned;
    this.driverPickups = data.driverPickups;
    this.freezerTemp = data.freezerTemp;
    this.coolerTemp = data.coolerTemp;
    this.dlvitms = data.dlvitms;
    this.crdts = data.crdts;
    this.pickups = data.pickups;
    this.totes = data.totes;
  }
}
