/** User model. */
export class User {
  /** User id. */
  public id: number;

  /** User email. */
  public email: string;

  /** User alternative email. */
  public alternativeEmail: string;

  /** User first name. */
  public firstName: string;

  /** User last name. */
  public lastName: string;

  /**
   * Constructor of user model.
   *
   * @param data User data.
   */
  public constructor(data: User) {
    this.id = data.id;
    this.email = data.email;
    this.alternativeEmail = data.alternativeEmail;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}
