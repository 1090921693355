export class Dlvitm {
  /** SKU. */
  public sku: string | null;

  /** Description. */
  public description: string | null;

  /** UPC1 code. */
  public upc1: string | null;

  /** UPC2 code. */
  public upc2: string | null;

  /** Quantity shipped. */
  public qtyShipped: number | null;

  /** Quantity delivered. */
  public qtyDelivered: number | null;

  /** Quantity difference. */
  public qtyDif: number | null;

  /** Container number. */
  public containerNo: string | null;

  /** Invoice number. */
  public invoiceNo: string | null;

  /** Container type. */
  public containerType: string | null;

  public constructor(data: Dlvitm) {
    this.sku = data.sku;
    this.description = data.description;
    this.upc1 = data.upc1;
    this.upc2 = data.upc2;
    this.qtyShipped = data.qtyShipped;
    this.qtyDelivered = data.qtyDelivered;
    this.qtyDif = data.qtyDif;
    this.containerNo = data.containerNo;
    this.invoiceNo = data.invoiceNo;
    this.containerType = data.containerType;
  }
}
