export class Pickup {
  /** No. */
  public no: string | null;

  /** SKU. */
  public sku: string | null;

  /** Description. */
  public description: string | null;

  /** UPC1 code. */
  public upc1: string | null;

  /** UPC2 code. */
  public upc2: string | null;

  /** Quantity to pick. */
  public qtyToPick: number | null;

  /** Quantity picked. */
  public qtyPicket: number | null;

  /** Reason. */
  public reason: string | null;

  public constructor(data: Pickup) {
    this.no = data.no;
    this.sku = data.sku;
    this.description = data.description;
    this.upc1 = data.upc1;
    this.upc2 = data.upc2;
    this.qtyToPick = data.qtyToPick;
    this.qtyPicket = data.qtyPicket;
    this.reason = data.reason;
  }
}
