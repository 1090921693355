import { axiosInstance } from './ApiService';
import TokenStorageService from './TokenStorageService';
import PlatformService from './PlatformService';

type SSOAuthParams = {
  token: string;
  userId: string;
};

/**
 * SSO Service.
 */
export const SSOService = {
  /**
   * Move a user to AD FS login form.
   *
   * @param returnUrl Callback URL after authentication in AD FS.
   */
  goToAdFs(returnUrl = window.location.origin): void {
    if (PlatformService.isAndroid() || PlatformService.isIOS()) {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/adfs?returnUrl=${process.env.REACT_APP_HOSTING_URL}/index`;
    } else {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/adfs?returnUrl=${returnUrl}`;
    }
  },

  /**
   * Get necessary URL params to SSO sign in.
   */
  getSSOAuthParamsFromUrl(): SSOAuthParams {
    const urlParams = this._getSearchParams();
    const token = urlParams.get('token');
    const userId = urlParams.get('userId');

    if (token && userId) {
      return { token, userId };
    }

    return null;
  },

  /**
   * Generate application JWT and set it lo the local storage.
   *
   * @param params
   * @param params.token AD FS token.
   * @param params.userId User id.
   */
  async generateJWTToken({ token, userId }: SSOAuthParams): Promise<string> {
    const response = await axiosInstance.post('/auth/adfs-generate-token', { userId: parseInt(userId, 10), token });
    const authToken = response.data.token;

    TokenStorageService.set(authToken);

    return authToken;
  },

  /**
   * Get URL search params.
   */
  _getSearchParams(): URLSearchParams {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return urlParams;
  },
};
