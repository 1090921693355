import React, { MouseEvent, ChangeEvent, useEffect, useState } from 'react';
import Select from 'react-select';

import './style.scss';

import BaseButton from '../BaseButton/BaseButton';
import Tooltip from '../Tooltip/Tooltip';
import SupportService from '../../core/services/SupportService';
import NotificationService from '../../core/services/NotificationService';
import { RoleFields } from './RoleFields/RoleFields';
import { StateOption } from '../../core/models';

export const REQUEST_ACCESS_MODAL_ID = 'requestAccessModal';

export enum Roles {
  Customer = 'Customer',
  Employee = 'Employee',
  ChainHeadquarters = 'ChainHeadquartersAccount',
}

/**
 * Request access dialog component.
 */
export default function RequestAccessDialog({ availableDivisions }): JSX.Element {
  const initialFormState = {
    customerOrEmployeeName: '',
    firstName: '',
    lastName: '',
    customerOrEmployeeEmail: '',
    email: '',
    divisionNumbers: [],
    customerAccountName: '',
    customerAccountNumber: '',
    coreMarkEmployeeRole: '',
    chainName: '',
    chainNumber: '',
    role: Roles.Customer,
  };
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const [error, setError] = useState(null);
  const [selectedDivisions, setSelectedDivisions] = useState([]);

  useEffect(() => {
    $(`#${REQUEST_ACCESS_MODAL_ID}`).on('show.bs.modal', () => {
      resetForm();
      resetSelect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError(null);
  }, [form]);

  const divisionOptions = availableDivisions.map(division => ({ value: division.id, label: division.name }));

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
  };

  const resetForm = (): void => {
    setForm(initialFormState);
  };

  const resetSelect = (): void => {
    setSelectedDivisions([]);
  };

  const setDivisionNumbers = (options: StateOption[]): void => {
    setSelectedDivisions(options);
    setForm({ ...form, divisionNumbers: [...options.map(elem => elem.value)] });
  };

  const handleSubmit = async (event: MouseEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await SupportService.requestAccess(form);
      NotificationService.push('success', 'Request has been sent successfully.', ' ');
      closeModal();
    } catch (error) {
      if (error.response && error.response.data) {
        const errors = error.response.data.errors;
        setError(Object.values(errors)[0][0]);
      }
    } finally {
      setLoading(false);
    }
  };

  const closeModal = (): void => {
    $(`#${REQUEST_ACCESS_MODAL_ID}`).modal('hide');
  };

  const { customerAccountName, customerAccountNumber, coreMarkEmployeeRole, chainName, chainNumber } = form;

  const showTooltip = form.role !== Roles.Employee;

  const divisionFieldLabel =
    form.role !== Roles.Employee
      ? 'Core-Mark Servicing OpCos (Division) Name(s):'
      : 'Core-Mark OpCo (Division) Name(s):';

  return (
    <div className="modal request-access-modal fade" id={REQUEST_ACCESS_MODAL_ID} tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button onClick={closeModal} className="close" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <img className="img-responsive logo" src="/assets/img/general/logo.png" alt="Core Source SOO logo" />
            <img
              className="img-responsive large-logo"
              src="/assets/img/general/logo-source-grey.png"
              alt="Core Source SOO grey logo"
            />
            <div className="form-notes">
              <p>Please complete this information form to gain access to CORE-SOURCE.</p>
              <p>You will receive an email confirming Core-Source Access and User Guide.</p>
              <br />
              <p>If you currently have imarkportal and Mobile APP credentials,</p>
              <p>please use the same email address for both.</p>
              <p>Division Name and Customer Account # can be found at the top of your invoice.</p>
              <p>
                <span className="red">*</span>These fields are mandatory to complete.
              </p>
            </div>
          </div>

          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <label className="radio">
                    <input
                      type="radio"
                      name="role"
                      value={Roles.Customer}
                      checked={form.role === Roles.Customer}
                      onChange={handleFieldChange}
                    />
                    A Core-Mark Customer
                    <small>(single or multiple site, but not a chain)</small>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="role"
                      value={Roles.Employee}
                      checked={form.role === Roles.Employee}
                      onChange={handleFieldChange}
                    />
                    A Core-Mark Employee
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="role"
                      value={Roles.ChainHeadquarters}
                      checked={form.role === Roles.ChainHeadquarters}
                      onChange={handleFieldChange}
                    />
                    A Core-Mark Chain Account
                    <small>(a chain account spanning one or multiple Core-Mark servicing OpCos (divisions))</small>
                  </label>
                </div>
              </div>
              <label>
                <span className="required-mark">*</span>First Name:
                <input
                  value={form.firstName}
                  onChange={handleFieldChange}
                  name="firstName"
                  className="line-input"
                  type="text"
                />
              </label>

              <label>
                <span className="required-mark">*</span>Last Name:
                <input
                  value={form.lastName}
                  onChange={handleFieldChange}
                  name="lastName"
                  className="line-input"
                  type="text"
                />
              </label>

              <label>
                <span className="required-mark">*</span>Email Address:
                <input
                  value={form.email}
                  onChange={handleFieldChange}
                  name="email"
                  className="line-input"
                  type="email"
                />
              </label>
              <div className="select-field">
                <label>
                  <span className="required-mark">*</span>
                  {divisionFieldLabel}
                </label>
                <Select
                  isMulti
                  name="divisionNumbers"
                  options={divisionOptions}
                  value={selectedDivisions}
                  hideSelectedOptions={false}
                  onChange={setDivisionNumbers}
                  className="division-multi-select"
                  classNamePrefix="division-select"
                />
              </div>

              {showTooltip && (
                <Tooltip>Division Name and Customer Account # can be found at the top of your invoice.</Tooltip>
              )}

              <RoleFields
                role={form.role}
                handleFieldChange={handleFieldChange}
                customerAccountName={customerAccountName}
                customerAccountNumber={customerAccountNumber}
                coreMarkEmployeeRole={coreMarkEmployeeRole}
                chainName={chainName}
                chainNumber={chainNumber}
              />

              <span className="error-message">{error}</span>

              <BaseButton
                loading={loading}
                className="btn btn-orange btn-lg btn-block"
                type="submit"
                disabled={loading}
              >
                Request Access
              </BaseButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
