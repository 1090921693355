export class CoreMarkShipment {
  /** Customer number. */
  public customerNo: string;

  /** Shipment number. */
  public shipmentNo: string;

  /** Ship from date. */
  public shipFromDate: string;

  /** Ship to date. */
  public shipToDate: string;

  /** Sequence number. */
  public seqNo: string;

  /** Ship date. */
  public shipDate: string;

  /** Route. */
  public route: string;

  /** Containers shipped quantity. */
  public containersShippedQty: number;

  /** Cases shipped quantity. */
  public casesShippedQty: number;

  /** Pieces shipped quantity. */
  public piecesShippedQty: number;

  /** Empty containers return quantity. */
  public emptyContainersRetQty: number;

  /** Pieces shorted quantity. */
  public piecesShortedQty: number;

  /** Items credited quantity. */
  public itemsCreditedQty: number;

  /** Items picked quantity. */
  public itemsPickedQty: number;

  /** Signed by. */
  public signedBy: string;

  /**
   * Shipment model constructor.
   *
   * @param data Shipment data.
   */
  public constructor(data: CoreMarkShipment) {
    this.customerNo = data.customerNo;
    this.shipmentNo = data.shipmentNo;
    this.shipFromDate = data.shipFromDate;
    this.shipToDate = data.shipToDate;
    this.seqNo = data.seqNo;
    this.shipDate = data.shipDate;
    this.route = data.route;
    this.containersShippedQty = data.containersShippedQty;
    this.casesShippedQty = data.casesShippedQty;
    this.piecesShippedQty = data.piecesShippedQty;
    this.emptyContainersRetQty = data.emptyContainersRetQty;
    this.piecesShortedQty = data.piecesShortedQty;
    this.itemsCreditedQty = data.itemsCreditedQty;
    this.itemsPickedQty = data.itemsPickedQty;
    this.signedBy = data.signedBy;
  }
}
