import React from 'react';

import './style.scss';

/** Collapse toggler button on header. */
const MenuButton: React.FC = () => {
  return (
    <button
      className="navbar-toggle collapsed"
      type="button"
      aria-label="Menu"
      data-toggle="collapse"
      data-target="#navbar"
      aria-expanded="false"
      aria-controls="navbar"
    >
      <span className="icon-bar" />
      <span className="icon-bar" />
      <span className="icon-bar" />
    </button>
  );
};

export default MenuButton;
