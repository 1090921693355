import React from 'react';
import classNames from 'classnames';

import './style.scss';

interface TooltipProps {
  alwaysShow?: boolean;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ alwaysShow, children }) => {
  return (
    <div className={classNames('help-tooltip', { 'always-show': alwaysShow })}>
      <img src="/assets/img/svg/ic-question.svg" alt="Question" />
      <span className="help-text">{children}</span>
    </div>
  );
};

export default Tooltip;
