import React, { ReactElement } from 'react';
import { QuestionLink } from '../../../core/models';
import { Link } from 'react-router-dom';
import { REQUEST_ACCESS_MODAL_ID } from '../../RequestAccessDialog/RequestAccessDialog';

interface ContactUsQuestionLinkProps {
  link: QuestionLink;
}

/**
 * Question link component in <ContactUs /> component.
 *
 * @param props.link Question link data.
 */
const ContactUsQuestionLink: React.FC<ContactUsQuestionLinkProps> = props => {
  const { href, reactLink, imagePath, label, secondaryLabel } = props.link;
  const imageAlt = `${label} link image`;

  const renderLink = (content): ReactElement => {
    if (href === REQUEST_ACCESS_MODAL_ID) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a data-toggle="modal" data-target={`#${REQUEST_ACCESS_MODAL_ID}`} type="button">
          {content}
        </a>
      );
    }

    return reactLink ? <Link to={href}>{content}</Link> : <a href={href}>{content}</a>;
  };

  return renderLink(
    <>
      <img src={imagePath} alt={imageAlt} />
      <div className="labels">
        <span className="menu-name">{label}</span>
        {secondaryLabel && <span className="secondary-name">{secondaryLabel}</span>}
      </div>
    </>,
  );
};

export default ContactUsQuestionLink;
