import { store } from 'react-notifications-component';

const DEFAULT_DURATION = 5000;

const NotificationService = {
  /**
   *
   * @param { 'danger' | 'success' | 'info' | 'default' | 'warning' } type Notification type.
   * @param title Notification title.
   * @param message Notification message.
   * @param duration Notification duration.
   */
  push(type: string, title: string, message: string, duration = DEFAULT_DURATION): void {
    store.addNotification({
      title,
      message,
      type,
      insert: 'right',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration,
      },
    });
  },
};

export default NotificationService;
