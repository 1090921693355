import React from 'react';

import './style.scss';

import HeaderBrand from './HeaderBrand/HeaderBrand';
import HeaderLinks from './HeaderLinks/HeaderLinks';

/** Root header component.*/
const Header: React.FC = () => {
  return (
    <header className="navbar navbar-default" role="navigation">
      <div className="container">
        <HeaderBrand />
        <HeaderLinks />
      </div>
    </header>
  );
};

export default Header;
