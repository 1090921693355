import React from 'react';

import './style.scss';

/** Logo component in footer. */
const LogoWhite: React.FC = () => {
  return (
    <a className="footer-logo" rel="noopener noreferrer" target="_blank" href="https://www.core-mark.com/">
      <img className="img-responsive" src="/assets/img/general/logo-footer.png" alt="Footer logo" />
    </a>
  );
};

export default LogoWhite;
