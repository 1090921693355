import React, { useState, createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import './styles/base/_common.scss';
import './styles/base/_custom-scroll.scss';
import './styles/base/_font.scss';
import 'react-notifications-component/dist/theme.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import AppRouter from './router';
import { User } from './core/models';

/** Context with user data and methods to update it. */
export const UserContext = createContext(null);

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);

  /** Save user data to the state fn(). */
  const setUserData = (userData: User): void => setUser(userData);

  /** Remove user data from state fn() */
  const resetUserData = (): void => setUser(null);

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ data: user, handleSetUser: setUserData, handleResetUser: resetUserData }}>
        <AppRouter />
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default App;
