/** Division model. */
export class Division {
  /** Division id. */
  public id: number;

  /** Division name. */
  public name: string;

  /** Division service email. */
  public serviceEmail: string;

  /** Division service phone. */
  public servicePhone: string;

  /**
   * Division model constructor.
   *
   * @param data Division data.
   */
  public constructor(data: Division) {
    this.id = data.id;
    this.name = data.name;
    this.serviceEmail = data.serviceEmail;
    this.servicePhone = data.servicePhone;
  }
}
