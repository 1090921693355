/**
 * PDI Session Storage Service.
 */

const KEY = '-=PDI_SESSION_TOKEN=-';

const PDISessionStorageService = {
  setSessionToken(token: string): void {
    sessionStorage.setItem(KEY, token);
  },

  getSessionToken(): string {
    return sessionStorage.getItem(KEY);
  },
};

export default PDISessionStorageService;
