/** Division model. */
export class DivisionSearchItem {
  /** Division id. */
  public id: number;

  /** Division name. */
  public name: string;

  /** Division service email. */
  public isDefault: boolean;

  /** Division service phone. */
  public isDisabled: boolean;

  /**
   * Division model constructor.
   *
   * @param data Division data.
   */
  public constructor(data: DivisionSearchItem) {
    this.id = data.id;
    this.name = data.name;
    this.isDefault = data.isDefault;
    this.isDisabled = data.isDisabled;
  }
}
