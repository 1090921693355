import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form/dist/types';

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string;
  placeholder?: string;
  name: string;
  defaultValue?: string | number;
  invalid?: FieldError | any;
  ref?: React.Ref<HTMLInputElement>;
}

/** Component for <input /> element. */
const BaseInput: React.FC<BaseInputProps> = forwardRef((props, ref) => {
  const { name, defaultValue, invalid } = props;

  const inputClassName = classNames('form-control', { invalid });

  return (
    <div className="form-group">
      <input className={inputClassName} name={name} defaultValue={defaultValue} ref={ref} {...props} />
    </div>
  );
});

BaseInput.displayName = 'BaseInput';

export default BaseInput;
