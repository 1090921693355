import { axiosInstance } from './ApiService';
import { ContactUsFormData } from '../models/ContactUsFormData';

/** Service based in user help. */
const SupportService = {
  /**
   * Send mail to support method.
   *
   * @param email User email.
   * @param questionsAndNeeds Mail message.
   * @param name User name.
   * @param customerAccountNumber Customer account number.
   * @param state Living state.
   */
  async sendHelpMail({
    email,
    questionsAndNeeds,
    name,
    customerAccountNumber,
    state,
  }: ContactUsFormData): Promise<void> {
    await axiosInstance.post('/user/contact-us', { email, questionsAndNeeds, name, customerAccountNumber, state });
  },

  /**
   * Request access action.
   *
   * @param formData Data to send.
   */
  async requestAccess(formData): Promise<void> {
    await axiosInstance.post('/user/request-access', { ...formData }, { withCredentials: true });
  },
};

export default SupportService;
