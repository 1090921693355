import React, { useState } from 'react';
import classNames from 'classnames';

import './../style.scss';

interface InstallButtonProps {
  url: string;
  onClick: () => void;
}

/**
 * InstallButton component.
 */
export default function InstallButton({ url, onClick }: InstallButtonProps): JSX.Element {
  const [visited, setVisited] = useState(false);

  const handleClick = (): void => {
    setVisited(true);
    onClick();
  };

  return (
    <div className="col-xs-12 mt10 mb10">
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className={classNames('btn btn-orange btn-submit btn-lg btn-multiline', { 'btn-visited': visited })}
        onClick={handleClick}
      >
        Click here to schedule PDI CSE Install
      </a>
    </div>
  );
}
