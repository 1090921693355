import { Customer } from '../models/Customer';
import { axiosInstance } from './ApiService';

/** Customer service. */
const CustomerService = {
  /**
   * Get customer list.
   */
  async getCustomersByCoreMarkId(id: number): Promise<Customer[]> {
    const result = await axiosInstance.get<Customer[]>(`/customer/${id}`);
    return result.data;
  },
};

export default CustomerService;
