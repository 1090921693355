import TokenStorageService from './TokenStorageService';
import { axiosInstance } from './ApiService';

/** Auth based service. */
const AuthService = {
  /**
   * Login user method.
   *
   * @param email User email.
   * @param password User password.
   * @param rememberMe Save user data sign.
   */
  async login({ email, password, rememberMe }): Promise<void> {
    const res = await axiosInstance.post('/auth', { email, password, rememberMe });

    TokenStorageService.set(res.data.token);
  },

  /**
   * Forgot user password method.
   *
   * @param email User email need to send mail.
   */
  async forgotPassword(email: string): Promise<void> {
    await axiosInstance.post('/auth/forgot-password', { email });
  },

  /** Logout user method. */
  logout(): void {
    return TokenStorageService.remove();
  },

  /** Refresh user access token method. */
  async refreshToken(): Promise<string> {
    const token = TokenStorageService.get();
    const { data } = await axiosInstance.put('/auth', { token });

    return data.token;
  },

  loggedIn(): boolean {
    return !!TokenStorageService.get();
  },
};

export default AuthService;
