declare let RoadnetActiveAlert: any;

/** Roadnet Active Alert Service. */
export const RoadnetActiveAlertService = {
  /** Deploy widget. */
  deployWidget(): void {
    RoadnetActiveAlert.deploy({
      recipient: 'DELIVERY',
      id: 'RAAWebApp',
      customerId: '99150000011IQPV',
      businessUnit: 'BU1',
      showSettingsButton: 'true',
      swapSettingsButton: 'false',
      title: 'Location = 3digit Div# +6digit Cust#',
    });
  },
};
