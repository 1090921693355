import React, { useEffect, useState } from 'react';

import { signOnModalId } from '../../pages/IndexPage/IndexPage';
import LogoWhite from '../Footer/FooterLogo/LogoWhite';
import { LoginFormData } from '../../core/models';
import AuthLoginForm from './AuthLoginForm/AuthLoginForm';
import ManualLoginForm from './ManualLoginForm/ManualLoginForm';
import './style.scss';

interface AuthModalProps {
  handleLogin: (data: LoginFormData) => void;
  handleAfterBiometrySuccess: () => void;
}

/** Auth form modal component. */
const AuthModal: React.FC<AuthModalProps> = props => {
  const { handleLogin, handleAfterBiometrySuccess } = props;

  const [form, setForm] = useState('login');

  useEffect(() => {
    const signOnModal = $(`#${signOnModalId}`);

    signOnModal.on('show.bs.modal', function() {
      setForm('login');
    });
  }, []);

  const renderForm = (): JSX.Element => {
    if (form === 'manualLogin')
      return <ManualLoginForm handleAfterBiometrySuccess={handleAfterBiometrySuccess} handleLogin={handleLogin} />;

    return <AuthLoginForm setForm={setForm} />;
  };

  return (
    <div
      className="modal fade auth-modal"
      id={signOnModalId}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="signOnModalLabel"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="logo-container">
              <LogoWhite />
            </div>
            <div className="text-center text-white">
              <h4 className="auth-form-title" id="signOnModalLabel">
                Sign on to access your account
              </h4>
              <hr className="separator" />
            </div>
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
