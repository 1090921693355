import React from 'react';

import './style.scss';

import DataLoader from '../DataLoader/DataLoader';

interface BaseButtonProps extends React.HTMLProps<HTMLButtonElement> {
  loading?: boolean;
  type: 'button' | 'submit' | 'reset';
}

const BaseButton: React.FC<BaseButtonProps> = props => {
  const { loading, children, ...rest } = props;

  const size = 15;
  const renderLoading = loading && <DataLoader type="TailSpin" size={size} color="#fff" className="loader" />;
  const renderEmpty = loading && <span style={{ width: size }}></span>;

  return (
    <button {...rest} style={styles.button}>
      {renderLoading}
      {children}
      {renderEmpty}
    </button>
  );
};

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default BaseButton;
