import React, { useState, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import './style.scss';

import CSEService from '../../core/services/CSEService';
import PDIService from '../../core/services/PDIService';
import NotificationService from '../../core/services/NotificationService';
import { transformObjectErrors } from '../../core/helpers/transformObjectErrors';

import Step1 from '../CSEDialog/Step1/Step1';
import Step2 from '../CSEDialog/Step2/Step2';
import Step3 from '../CSEDialog/Step3/Step3';
import Step4 from '../CSEDialog/Step4/Step4';
import { CSESignup } from '../../core/models/CSESignup';
import { transformDivisionNames } from '../../core/helpers/transformDivisionNames';
import { initialFormState } from './Step1/formSettings';
import ConfirmCloseModal from './ConfirmCloseModal/ConfirmCloseModal';

export const CSE_MODAL_ID = 'CSEModal';
export const CONFIRM_CLOSE_MODAL_ID = 'ConfirmCloseModal';
const ANIMATION_DURATION = 600;

enum ActiveStep {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

/**
 * CSE dialog component.
 */
export default function CSEDialog({ availableDivisions }): JSX.Element {
  const history = useHistory();
  const [formData, setFormData] = useState(null);
  const [errorStep3, setErrorStep3] = useState(null);
  const [errorStep4, setErrorStep4] = useState(null);
  const [activeStep, setActiveStep] = useState(ActiveStep.One);
  const [docuSignUrl, setDocuSignUrl] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [docuSignPageTitle, setDocuSignPageTitle] = useState('C-Store Essentials Agreement');

  const isCombined = (data): boolean => {
    return (
      (data.scanData && data.backOffice && data.topOffRewards) ||
      (data.scanData && data.topOffRewards) ||
      (data.backOffice && data.topOffRewards)
    );
  };

  // Submit form Step 1
  const handleSubmitStep1 = async (data): Promise<void> => {
    setSubmitting(true);

    const preFilledData = {
      firstName: data.user[0].firstName,
      lastName: data.user[0].lastName,
      email: data.user[0].email,
    };

    // Submit Top-Off form to pre-fill and sign PDF.
    if (data.topOffRewards && !data.scanData && !data.backOffice) {
      const responsePreFill = await CSEService.preFillTopOff(preFilledData);

      setDocuSignUrl(responsePreFill.data.url);
      setFormData({ ...data, contractDocumentId: responsePreFill.data.documentId });

      await PDIService.loginPDI();

      setActiveStep(ActiveStep.Two);
      setDocuSignPageTitle('Top-Off Rewards Agreement');
      setSubmitting(false);

      return;
    }

    // Submit Combined form to pre-fill and sign PDF.
    if (isCombined(data)) {
      const responsePreFill = await CSEService.preFillCombined(preFilledData);

      setDocuSignUrl(responsePreFill.data.url);
      setFormData({ ...data, contractDocumentId: responsePreFill.data.documentId });
      setActiveStep(ActiveStep.Two);
      setDocuSignPageTitle('CSE & Top Off Agreement');
      setSubmitting(false);

      return;
    }

    // Submit CSE form to pre-fill and sign PDF.
    if (data.scanData || data.backOffice || (data.scanData && data.backOffice)) {
      const responsePreFill = await CSEService.preFillCSE(preFilledData);

      setDocuSignUrl(responsePreFill.data.url);
      setFormData({ ...data, contractDocumentId: responsePreFill.data.documentId });
      setActiveStep(ActiveStep.Two);
      setSubmitting(false);
    }
  };

  // Submit form Step 2
  const handleSubmitStep2 = async (data): Promise<void> => {
    setSubmitting(true);
    await PDIService.loginPDI();

    setFormData({
      ...data,
      user: data.user.map(user => {
        return {
          ...user,
          cseNumber: data.cseNumber,
        };
      }),
    });
    if (isCombined(data)) {
      setActiveStep(ActiveStep.Three);
      setSubmitting(false);

      return;
    }

    if (data.scanData || data.backOffice) {
      setActiveStep(ActiveStep.Three);
      setSubmitting(false);

      return;
    }

    if (data.topOffRewards && data.cseNumber === '') {
      setActiveStep(ActiveStep.Three);
      setSubmitting(false);

      return;
    }

    if (data.topOffRewards && data.cseNumber !== '') {
      const preparedData = {
        ...data,
        firstName: data.user[0].firstName,
        lastName: data.user[0].lastName,
        phone: data.user[0].phone,
        email: data.user[0].email,
        storeName: data.user[0].storeName,
        address: data.user[0].street,
        city: data.user[0].city,
        state: data.user[0].state,
        zipCode: data.user[0].zip,
        customerAccountName: data.user[0].userName,
        customerAccountNumber: data.user[0].accountNumber,
        divisionNumbers: data.user[0].brandMetaData.coreMarkDivisionName,
        posSystem: data.user[0].posSystem,
        pdiSolutionToEnrollIn: data.pdiSolutionToEnrollIn,
        contractDocumentId: data.contractDocumentId,
        cseNumber: data.cseNumber,
        oneTimeLoginURL: '',
        userName: data.userName,
        password: data.password,
        storeCoreMarkAccountNumber: data.accountNumber,
        salesRepresentativeFullName: data.salesRepresentativeFullName,
        salesRepresentativeEmail: data.salesRepresentativeEmail,
      };

      try {
        await CSEService.saveContracts(preparedData);

        setFormData({
          ...data,
          user: data.user.map(user => {
            return {
              ...user,
              cseNumber: data.cseNumber,
              salesRepresentativeFullName: data.salesRepresentativeFullName,
              salesRepresentativeEmail: data.salesRepresentativeEmail,
            };
          }),
        });
        setActiveStep(ActiveStep.Four);
        setSubmitting(false);
      } catch (error) {
        setErrorStep4(error.response.message);
      }

      return;
    }
  };

  // Submit form Step 3
  const handleSubmitStep3 = async (data): Promise<void> => {
    let successCounter = 0;

    setSubmitting(true);

    for (const [index, item] of data.user.entries()) {
      const preparedData: CSESignup = {
        store: {
          storeName: item.storeName,
          email: item.email,
          phone: item.phone,
          accountType: item.accountType,
          plan: item.plan,
          addon: item.addon,
          fuelBrand: item.fuelBrand,
          street: item.street,
          city: item.city,
          state: item.state,
          zip: item.zip,
          country: item.country,
          brandMetaData: {
            coreMarkDivisionName: transformDivisionNames(item.brandMetaData.coreMarkDivisionName, availableDivisions),
            coreMarkAccountId: item.accountNumber,
            additionalProp3: item.brandMetaData.additionalProp3,
          },
        },
        user: {
          firstName: item.firstName,
          lastName: item.lastName,
          userName: item.userName,
          password: item.password,
        },
      };

      try {
        // Skip if PDI were added previously.
        if (!item.pdi) {
          const responseSignupPDI = await PDIService.signupPDI(preparedData);
          const updatedUser = (data.user[index].pdi = responseSignupPDI);

          setFormData({
            ...data,
            user: data.user.map((user, i) => {
              if (index === i) {
                user[index] = updatedUser;
              }

              return {
                ...user,
                cseNumber: data.cseNumber,
                salesRepresentativeFullName: data.salesRepresentativeFullName,
                salesRepresentativeEmail: data.salesRepresentativeEmail,
              };
            }),
          });
        }

        successCounter++;
      } catch (error) {
        const { error: serverError } = error.response?.data;

        if (serverError && typeof serverError === 'object') {
          setErrorStep3({ index, message: transformObjectErrors(serverError) });
        } else {
          if (serverError === 'Username exist, Please try with new one.') {
            setErrorStep3({ index, message: `User "${item.userName}" exists, Please try with new one.` });
          } else {
            setErrorStep3(serverError);
          }
        }

        setSubmitting(false);
      }
    }

    // Check if there are no errors.
    if (successCounter === data.user.length) {
      successCounter = 0;
      setSubmitting(true);

      // Check if all PDI were added and then submit contracts.
      if (data.user.every(user => Boolean(user.pdi))) {
        for (const item of data.user) {
          const preparedData = {
            firstName: item.firstName,
            lastName: item.lastName,
            phone: item.phone,
            email: item.email,
            storeName: item.storeName,
            address: item.street,
            city: item.city,
            state: item.state,
            zipCode: item.zip,
            customerAccountName: item.userName,
            customerAccountNumber: item.accountNumber,
            divisionNumbers: item.brandMetaData.coreMarkDivisionName,
            posSystem: item.posSystem,
            pdiSolutionToEnrollIn: data.pdiSolutionToEnrollIn,
            contractDocumentId: data.contractDocumentId,
            cseNumber: data.cseNumber !== '' ? data.cseNumber : item.pdi?.cspAccountNo,
            oneTimeLoginURL: item.pdi?.oneTimeLoginURL,
            userName: item.userName,
            password: item.password,
            storeCoreMarkAccountNumber: item.accountNumber,
            salesRepresentativeFullName: data.salesRepresentativeFullName,
            salesRepresentativeEmail: data.salesRepresentativeEmail,
          };

          try {
            await CSEService.saveContracts(preparedData);

            successCounter++;
          } catch (error) {
            setErrorStep4(error.response.message);
          }
        }
        setActiveStep(ActiveStep.Four);
      }

      setSubmitting(false);
    }
  };

  // Submit form Step 4
  const handleSubmitStep4 = (): void => {
    closeModal();
    NotificationService.push('success', 'Request has been sent successfully.', ' ');
  };

  const handleCancelConfirmModalClick = (): void => {
    $(`#${CONFIRM_CLOSE_MODAL_ID}`).modal('hide');
    setTimeout(() => {
      $('body').addClass('modal-open');
    }, ANIMATION_DURATION);
  };

  const closeModal = (): void => {
    setFormData(initialFormState);
    $(`#${CSE_MODAL_ID}`).modal('hide');
    history.push('/');
    setTimeout(() => {
      $('body')
        .removeClass('modal-open')
        .removeAttr('style');
    }, ANIMATION_DURATION);
  };

  const handleConfirmCloseModal = (): void => {
    $(`#${CONFIRM_CLOSE_MODAL_ID}`).modal('show');
    setTimeout(() => {
      $('.modal-backdrop')
        .eq(1)
        .css('z-index', '1050');
    }, 0);
  };

  const handleCloseConfirmModalClick = (): void => {
    closeModal();
    $(`#${CONFIRM_CLOSE_MODAL_ID}`).modal('hide');
  };

  const getActiveForm = (): ReactNode => {
    switch (activeStep) {
      case ActiveStep.One: {
        return <Step1 onSubmit={handleSubmitStep1} submitting={submitting} availableDivisions={availableDivisions} />;
      }

      case ActiveStep.Two: {
        return (
          <Step2
            onSubmit={handleSubmitStep2}
            submitting={submitting}
            docuSignUrl={docuSignUrl}
            initialValues={formData}
            error={errorStep4}
            title={docuSignPageTitle}
          />
        );
      }

      case ActiveStep.Three: {
        return (
          <Step3
            onSubmit={handleSubmitStep3}
            submitting={submitting}
            availableDivisions={availableDivisions}
            initialValues={formData}
            error={errorStep3}
          />
        );
      }

      case ActiveStep.Four: {
        return (
          <Step4
            onSubmit={handleSubmitStep4}
            availableDivisions={availableDivisions}
            initialValues={formData}
            error={errorStep4}
            isCombined={isCombined(formData)}
          />
        );
      }

      default: {
        return <Step1 onSubmit={handleSubmitStep1} submitting={submitting} availableDivisions={availableDivisions} />;
      }
    }
  };

  return (
    <>
      <div
        className="modal cse-modal fade"
        id={CSE_MODAL_ID}
        tabIndex={-1}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={handleConfirmCloseModal} className="close" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <img className="img-responsive logo" src="/assets/img/general/logo.png" alt="Core Source SOO logo" />
            </div>
            <div className="modal-body">{getActiveForm()}</div>
          </div>
        </div>
      </div>
      <ConfirmCloseModal
        id={CONFIRM_CLOSE_MODAL_ID}
        onCancel={handleCancelConfirmModalClick}
        onClose={handleCloseConfirmModalClick}
      />
    </>
  );
}
