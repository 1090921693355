import React, { FC, ReactNode } from 'react';
import './style.scss';

type Props = {
  readonly error?: string;
  readonly label?: string;
  readonly children: ReactNode;
};

export const FormItem: FC<Props> = ({ error, children }) => {
  return (
    <div className="formItem">
      <div className="formItemContent">{children}</div>
      {error && <span className="formItemError">{error}</span>}
    </div>
  );
};
