import { CategoryLink } from './CategoryLink';

/** Category model. */
export class Category {
  /** Category's id. */
  public id: number;

  /** Category's name. */
  public name: string;

  /** Category's main color. */
  public color: string;

  /** Category's logo image. */
  public headerImageUrl: string;

  /** Category's face background image. */
  public centralImageUrl: string;

  /** Category's resources links. */
  public menuItems: CategoryLink[];

  /** Category's disabled state. */
  public isAccessible: boolean;

  /**
   * Category model constructor.
   *
   * @param data Category instance data.
   */
  public constructor(data: Category) {
    this.id = data.id;
    this.name = data.name;
    this.color = data.color;
    this.headerImageUrl = data.headerImageUrl;
    this.centralImageUrl = data.centralImageUrl;
    this.menuItems = data.menuItems;
    this.isAccessible = data.isAccessible;
  }
}
