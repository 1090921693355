import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import './style.scss';

import BaseInput from '../../BaseInput/BaseInput';
import AuthModalValidationError from '../AuthModalValidationError/AuthModalValidationError';
import FingerprintIcon from '../../icons/FingerprintIcon';
import FaceIDIcon from '../../icons/FaceIDIcon';
import useBiometry from '../../../core/hooks/useBiometric';
import BiometricService from '../../../core/services/BiometricService';
import TokenStorageService from '../../../core/services/TokenStorageService';
import BaseButton from '../../BaseButton/BaseButton';
import { SSOService } from '../../../core/services/SSOService';

interface AuthLoginFormProps {
  handleLogin: (data) => void;
  handleAfterBiometrySuccess: () => unknown;
}

/** Manual Login form component. */
const ManualLoginForm: React.FC<AuthLoginFormProps> = props => {
  const { handleLogin, handleAfterBiometrySuccess } = props;
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidDataError, setInvalidDataError] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
  });
  const { isBiometryAvailable, availableBiometryType } = useBiometry();

  /** Disable button effect when for has validation errors. */
  useEffect(() => {
    loading || Object.values(methods.formState.errors).length ? setButtonDisabled(true) : setButtonDisabled(false);
  }, [loading, methods.formState.errors]);

  /** Open dialog window of asking user to use biometry to for authentication if this is available. */
  const openBiometryPermissionDialog = (): void => {
    if (TokenStorageService.get() && BiometricService.permissionGiven() === null && isBiometryAvailable) {
      BiometricService.openPermissionDialog(availableBiometryType);
    }
  };

  /** Login button click handler. */
  const onSubmit = async (data): Promise<void> => {
    try {
      setInvalidDataError(false);
      setLoading(true);
      await handleLogin(data);
      methods.reset();
      openBiometryPermissionDialog();
    } catch (error) {
      const status = error.response && error.response.data.status;

      if (status === 400 || status === 404) {
        setInvalidDataError(true);
      }
    }
    setLoading(false);
  };

  /**
   * Handle SSO sign in action.
   */
  const handleSsoSignIn = (): void => {
    SSOService.goToAdFs();
  };

  /** Render required user name error. */
  const renderEmailRequiredError = methods.formState.errors.email && (
    <AuthModalValidationError>User name is required.</AuthModalValidationError>
  );

  /** Render required password error. */
  const renderPasswordRequiredError = methods.formState.errors.password && (
    <AuthModalValidationError>Password is required.</AuthModalValidationError>
  );

  /** Render common invalid data error. */
  const renderInvalidDataError = invalidDataError && (
    <AuthModalValidationError classes="text-center">Invalid email or password.</AuthModalValidationError>
  );

  /** Render button depends on type of available biometry. */
  const renderBiometryIcon = (): JSX.Element => {
    if (BiometricService.permissionGiven() && isBiometryAvailable) {
      if (availableBiometryType === 'finger') {
        return <FingerprintIcon onClick={(): void => BiometricService.useBiometric(handleAfterBiometrySuccess)} />;
      }

      if (availableBiometryType === 'face') {
        return <FaceIDIcon onClick={(): void => BiometricService.useBiometric(handleAfterBiometrySuccess)} />;
      }
    }
  };

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <BaseInput type="email" placeholder="Email Address" name="email" {...methods.register('email')} />
        {renderEmailRequiredError}

        <BaseInput type="password" placeholder="Password" name="password" {...methods.register('password')} />
        {renderPasswordRequiredError}

        <div className="checkbox">
          <label className="text-uppercase color-white save-username-label">
            <input type="checkbox" name="rememberMe" {...methods.register('rememberMe')} />{' '}
            <span>Save Email Address</span>
          </label>
          {renderBiometryIcon()}
        </div>

        {renderInvalidDataError}

        <BaseButton
          loading={loading}
          className="btn btn-orange btn-lg btn-block"
          type="submit"
          disabled={buttonDisabled}
        >
          Submit
        </BaseButton>
        <BaseButton className="btn btn-orange btn-lg btn-block" type="button" onClick={handleSsoSignIn}>
          CORE•SOURCE SIGN IN (SSO)
        </BaseButton>
      </form>
    </>
  );
};

export default ManualLoginForm;
