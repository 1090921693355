import React from 'react';

import BaseButton from '../../BaseButton/BaseButton';
import { SSOService } from '../../../core/services/SSOService';

interface AuthLoginFormProps {
  setForm: (formType: string) => void;
}

/** Login form component. */
const AuthLoginForm: React.FC<AuthLoginFormProps> = props => {
  const { setForm } = props;

  /** Handle click on manual login button (render manual login form). */
  const handleManualLoginClick = (): void => {
    setForm('manualLogin');
  };

  /**
   * Handle SSO sign in action.
   */
  const handleSsoSignIn = (): void => {
    SSOService.goToAdFs();
  };

  return (
    <>
      <div>
        <BaseButton className="btn btn-orange btn-lg btn-block" type="button" onClick={handleSsoSignIn}>
          CORE•SOURCE SIGN IN (SSO)
        </BaseButton>
        <BaseButton className="btn btn-link btn-block color-white" type="button" onClick={handleManualLoginClick}>
          Manual Login
        </BaseButton>
      </div>
    </>
  );
};

export default AuthLoginForm;
