import React, { ChangeEvent, forwardRef } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import classNames from 'classnames';

import './style.scss';

interface BaseSelectProps {
  options: any[];
  name: string;
  invalid?: FieldError | any;
  ref?: React.Ref<HTMLSelectElement>;
  value?: string;
  onChangeForController?: (value: string) => void;
}

/** Base select component. */
const BaseSelect: React.FC<BaseSelectProps> = forwardRef((props, ref) => {
  const { options, name, invalid, value, onChangeForController } = props;

  const selectClassName = classNames('form-control has-placeholder', {
    invalid,
    selectPlaceholder: options[0].value === value && options[0].disabled,
  });

  const getOptionClassName = (isDisable: boolean): string => {
    return classNames('option', {
      optionDisable: isDisable,
    });
  };

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    onChangeForController(event.target.value);
  };

  const renderOptions = options.map((option, index: number) => {
    const { value, disabled, label } = option;

    return (
      <option className={getOptionClassName(disabled)} key={index} value={value} disabled={disabled}>
        {label}
      </option>
    );
  });

  return (
    <div className="form-group">
      <select
        value={value}
        className={selectClassName}
        defaultValue=""
        name={name}
        ref={ref}
        onChange={onSelectChange}
        {...props}
      >
        {renderOptions}
      </select>
    </div>
  );
});

BaseSelect.displayName = 'BaseSelect';

export default BaseSelect;
