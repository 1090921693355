const degreesFormattedSyntax = new Intl.NumberFormat('en-US', {
  style: 'unit',
  unit: 'fahrenheit',
});

export function formatDegrees(degrees: number): string | null {
  if (isNaN(degrees)) {
    return null;
  }
  return degreesFormattedSyntax.format(degrees);
}
