import React from 'react';
import { TableColumn, TableComponent } from '../../../components/Table/Table';
import { Credit } from '../../../core/models/Credit';

const columns: TableColumn<Credit>[] = [
  {
    columnName: 'Credit SKU',
    key: 'sku',
  },
  {
    columnName: 'Credit Description',
    key: 'description',
    width: '240px',
  },
  {
    columnName: 'Credit UPC 1',
    key: 'upc1',
  },
  {
    columnName: 'Credit UPC 2',
    key: 'upc2',
  },
  {
    columnName: 'Quantity Credited',
    key: 'qtyCredited',
  },
  {
    columnName: 'Credit Container',
    key: 'container',
  },
  {
    columnName: 'Credit Invoice',
    key: 'invoice',
  },
  {
    columnName: 'Credit Reason',
    key: 'reason',
  },
];

type Props = {
  isLoading: boolean;
  data: Credit[];
};

export const DriverCreditsTable: React.FC<Props> = ({ isLoading, data }) => {
  return (
    <div>
      <h2>Driver Credits</h2>
      <TableComponent isLoading={isLoading} data={data} columns={columns} />
    </div>
  );
};
