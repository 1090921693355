import React from 'react';
import ReactNotification from 'react-notifications-component';

import './style.scss';

import { Header, Footer } from '../../components';

const AppLayout: React.FC = props => {
  return (
    <>
      <ReactNotification className="notificator" />
      <Header />
      <main className="container main-container">{props.children}</main>
      <Footer />
    </>
  );
};

export default AppLayout;
