import React from 'react';
import { TableColumn, TableComponent } from '../../../components/Table/Table';
import { Tote } from '../../../core/models/Tote';

const columns: TableColumn<Tote>[] = [
  {
    columnName: 'Container Type',
    key: 'containerType',
  },
  {
    columnName: 'Qty Allocated',
    key: 'qtyAllocated',
  },
  {
    columnName: 'Qty Returned',
    key: 'qtyReturned',
  },
];

type Props = {
  isLoading: boolean;
  data: Tote[];
};

export const DriverEmptyContainersReturnTable: React.FC<Props> = ({ isLoading, data }) => {
  return (
    <div>
      <h2>Empty Container Returns</h2>
      <TableComponent isLoading={isLoading} data={data} columns={columns} />
    </div>
  );
};
