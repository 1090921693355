import React from 'react';
import classNames from 'classnames';

import './../style.scss';
import { transformDivisionNames } from '../../../core/helpers/transformDivisionNames';
import Tooltip from '../../../components/Tooltip/Tooltip';
import InstallButton from './InstallButton';

interface UserDetailsPreviewProps {
  data: any[];
  availableDivisions: any[];
  onCSEInstallClick: () => void;
}

/**
 * UserDetailsPreview component.
 */
export default function UserDetailsPreview({
  data,
  availableDivisions,
  onCSEInstallClick,
}: UserDetailsPreviewProps): JSX.Element {
  const accountNumbers = new Set<number>([]);

  const handleCSEInstallClick = (cspAccountNo: number): void => {
    accountNumbers.add(cspAccountNo);

    if (accountNumbers.size === data.length) {
      onCSEInstallClick();
    }
  };

  return (
    <>
      {data?.map((item, index) => {
        return (
          <div key={index} className={classNames('user-details-wrapper', { first: index === 0 })}>
            <div className="row">
              <div className="col-md-12">
                <div className="text-divider">
                  <span className="text">Sales Representative Information</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>Full Name:</label>
                <span>{item.salesRepresentativeFullName}</span>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>Email Address:</label>
                <span>{item.salesRepresentativeEmail}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-divider">
                  <span className="text">Customer Information</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>First Name:</label>
                <span>{item.firstName}</span>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>Last Name:</label>
                <span>{item.lastName}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>Phone:</label>
                <span>{item.phone}</span>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>Email Address:</label>
                <span>{item.email}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="text-divider">
                  <span className="text">Store Details</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>Store Name:</label>
                <span>{item.storeName}</span>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>Address:</label>
                <span>{item.street}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>City:</label>
                <span>{item.city}</span>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>State:</label>
                <span>{item.state}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>Zip Code:</label>
                <span>{item.zip}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>Store Core-Mark Account Number:</label>
                <span>{item.accountNumber}</span>
              </div>
              <div className="col-xs-12 col-md-6">
                <label>Division Name:</label>
                <span>{transformDivisionNames(item.brandMetaData.coreMarkDivisionName, availableDivisions)}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <label>What POS system do you have?</label>
                <span>{item.posSystem}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="text-divider">
                  <span className="text">PDI Details</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <label>CSE Number:</label>
                <span>{item.cseNumber !== '' ? item.cseNumber : item.pdi?.cspAccountNo}</span>
              </div>
              {item.pdi?.oneTimeLoginURL && (
                <div className="col-xs-12 mt10 mb10">
                  <InstallButton
                    url={item.pdi?.oneTimeLoginURL}
                    onClick={(): void => handleCSEInstallClick(item.pdi?.cspAccountNo)}
                  />
                </div>
              )}
            </div>

            {item.pdi?.oneTimeLoginURL && (
              <Tooltip alwaysShow>
                Please click this one-time link to schedule your PDI CStore Essentials install! After completing this
                step, you will be complete with the Sign Up Process! You will receive an email receipt with today’s
                information and next steps!
              </Tooltip>
            )}
          </div>
        );
      })}
    </>
  );
}
