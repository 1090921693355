import React, { ReactNode } from 'react';
import './style.scss';

type LooseAutocomplete<TItems extends string | number | symbol> = TItems | (string & {});

type TableProps<TData> = {
  data: TData[];
  columns: TableColumn<TData>[];
  onRowClick?: (param: TData) => void;
  isLoading?: boolean;
};

export type TableColumn<T> = {
  key: LooseAutocomplete<keyof T>;
  columnName: string;
  width?: string;
  renderColumn?: (param: T) => ReactNode;
};

/** Table component. */
export const TableComponent = <TData,>({
  columns,
  data,
  isLoading = false,
  onRowClick,
}: TableProps<TData>): React.JSX.Element => {
  const hasData = data.length > 0;
  return (
    <div className="table-container">
      <table className="table table-hover">
        <thead>
          <tr>
            {columns.map(column => (
              <th
                style={{ width: column.width }}
                className="table-container__column"
                key={column.key.toString()}
                scope="col"
              >
                {column.columnName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem, index) => (
            <tr key={index} onClick={(): void => onRowClick?.(dataItem)}>
              {columns.map((col, index) => {
                const data = dataItem[col.key as keyof TData];
                return col.renderColumn ? (
                  <td key={index}>{col.renderColumn(dataItem)}</td>
                ) : (
                  <td key={index}>{(data != null && data !== "") ? data : '-'}</td>
                );
              })}
            </tr>
          ))}
          {isLoading && (
            <tr>
              <th className="table-text" colSpan={columns.length}>
                Loading data ...
              </th>
            </tr>
          )}
          {!isLoading && !hasData && (
            <tr>
              <th className="table-text" colSpan={columns.length}>
                No data.
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
