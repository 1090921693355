import React from 'react';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './style.scss';

import BaseButton from '../../BaseButton/BaseButton';
import { initialUserState } from '../shared/initialUserState';
import UserDetails from './../UserDetails/UserDetails';
import { formValidation, initialFormState } from './formSettings';

/**
 * Step 3 form component.
 */
export default function Step3({ initialValues, availableDivisions, submitting, error, onSubmit }): JSX.Element {
  const methods = useForm({
    resolver: yupResolver(formValidation),
    defaultValues: { ...initialFormState, ...initialValues },
    shouldFocusError: false,
  });
  const {
    formState: { errors },
    watch,
    setValue,
    getValues,
    handleSubmit,
    register,
  } = methods;

  const watchUser = watch('user', initialValues.user);

  const handleFormSubmit = (): void => {
    onSubmit(getValues());
  };

  const handleEnrollAnotherStore = (): void => {
    const user = getValues('user');
    const updatedUser = [
      ...user,
      {
        ...initialUserState,
        plan: initialValues.user[0].plan,
        addon: initialValues.user[0].addon,
        firstName: initialValues.user[0].firstName,
        lastName: initialValues.user[0].lastName,
        phone: initialValues.user[0].phone,
        email: initialValues.user[0].email,
      },
    ];
    setValue('user', updatedUser);
  };

  const handleRemoveAnotherStore = (index: number): void => {
    const user = getValues('user');
    const updated = user.filter((_: undefined, userIndex: number) => index !== userIndex);

    setValue('user', updated);
  };

  return (
    <FormProvider {...methods}>
      <h2 className="text-center">C-Store Essentials</h2>
      <div className="form-notes">
        <p>
          <span className="red">*</span>These fields are mandatory to complete.
        </p>
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="cse-step3" noValidate>
        <UserDetails
          availableDivisions={availableDivisions}
          onEnrollAnotherStore={handleEnrollAnotherStore}
          onRemoveEnrollAnotherStore={handleRemoveAnotherStore}
          data={watchUser}
          serverErrors={error}
          hasPasswordFields
        />

        <div className="row">
          <div className="col-md-12">
            <div className="select-field">
              <div className="checkbox-with-mark">
                <label className={classNames('checkbox', { invalid: errors.iHavePC })}>
                  <span className="required-mark">*</span>
                  <input {...register('iHavePC')} type="checkbox" />I have a stationary windows PC on site that is
                  connected to my back office
                </label>
              </div>
            </div>
          </div>
        </div>

        <BaseButton
          className="btn btn-orange btn-submit btn-lg btn-block"
          type="submit"
          disabled={submitting}
          loading={submitting}
        >
          Next
        </BaseButton>
      </form>
    </FormProvider>
  );
}
