import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';

const AppUrlListener: React.FC<unknown> = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (data: any) => {
      const slug = data.url.split('.com').pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
