import React from 'react';
import classNames from 'classnames';

/** <HeaderLink /> component props interface. */
export interface HeaderLinkProps {
  active?: boolean;
  href: string;
  text: string;
  icon: string;
}

/**
 * Side menu component.
 *
 * @param props Component props.
 * @param props.active Link active sign.
 * @param props.href Link path.
 * @param props.text Link label text.
 * @param props.icon Link icon location.
 */
const HeaderLink: React.FC<HeaderLinkProps> = props => {
  const { active, href, text, icon } = props;
  const linkClassName = classNames({ active });

  return (
    <li className={linkClassName}>
      <a href={href}>
        <img src={icon} alt={text} />
        <span className="menu-name">{text}</span>
      </a>
    </li>
  );
};

export default HeaderLink;
