import React from 'react';

import './style.scss';

interface BannerProps {
  text: string;
}

/**
 * Top banner component.
 *
 * @param props.text Banner text.
 */
const AppBanner: React.FC<BannerProps> = props => {
  const { text } = props;

  return (
    <div className="block-banner">
      <h3 className="banner-text">{text}</h3>
    </div>
  );
};

export default AppBanner;
