import React, { FC, useEffect, useState } from 'react';
import { TableColumn, TableComponent } from '../../components/Table/Table';
import AppLayout from '../../containers/AppLayout/AppLayout';
import { useSearchParam } from '../../core/hooks/useSearchParam';
import './style.scss';
import { CoreMarkShipment } from '../../core/models/CoreMarkShipment';
import CoreMarkService, { ShipmentsFilterParams } from '../../core/services/CoreMarkService';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const columns: TableColumn<CoreMarkShipment>[] = [
  {
    columnName: 'Route #',
    key: 'route',
  },
  {
    columnName: 'Ship Date',
    key: 'shipDate',
    renderColumn: (col) => col.shipDate ? moment(col.shipDate).format("MM/DD/YYYY"): "-" 
  },
  {
    columnName: '# of Containers Delivered',
    key: 'containersShippedQty',
  },
  {
    columnName: '# of Cases Delivered',
    key: 'casesShippedQty',
  },
  {
    columnName: '# of Pieces Delivered',
    key: 'piecesShippedQty',
  },
  {
    columnName: '# of Empty Containers Returned',
    key: 'emptyContainersRetQty',
  },
  {
    columnName: '# of Pieces Shorted',
    key: 'piecesShortedQty',
  },
  {
    columnName: '# of Items Credited',
    key: 'itemsCreditedQty',
  },
  {
    columnName: '# of Items Picked Up',
    key: 'itemsPickedQty',
  },
  {
    columnName: 'Accepted By',
    key: 'signedBy',
  },
];

export const ShippingPage: FC = () => {
  const searchParams = useSearchParam();
  const history = useHistory();
  const [shipments, setShipments] = useState<CoreMarkShipment[]>([]);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const customerNo = searchParams.get('customer-no');
  const startDate = searchParams.get('start-date');
  const endDate = searchParams.get('end-date');

  const hasCustomerId = customerNo != null;
  const hasStartDate = startDate != null;
  const hasEndDate = endDate != null;

  const shouldShowTable =
    hasCustomerId && hasStartDate && hasEndDate && moment(startDate).isValid() && moment(endDate).isValid();

  const getShipments = async (params: ShipmentsFilterParams): Promise<void> => {
    try {
      setIsTableLoading(true);
      const shipmentsList = await CoreMarkService.getShipments(params);
      setShipments(shipmentsList);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    if (!shouldShowTable) {
      return;
    }
    getShipments({ customerNo: customerNo, shipFrom: startDate, shipTo: endDate });
  }, [customerNo, startDate, endDate, shouldShowTable]);

  const handleRowClick = (shipping: CoreMarkShipment): void => {
    history.push({
      pathname: `/shipping/${shipping.shipmentNo}`,
      search: `seq-no=${shipping.seqNo}&customer-no=${shipping.customerNo}`,
    });
  };

  const handleNavigateBack = (): void => {
    history.push({ pathname: `/`, search: searchParams.toString() });
  };

  return (
    <AppLayout>
      <div className="shipping-page__title">
        <button onClick={handleNavigateBack} className="shipping-page__back-button">
          <img className="shipping-page__back-button-icon" src="/assets/img/svg/ic-back.svg" alt="Back button" />
        </button>
        <h1 className="shipping-page__heading">Driver manifests</h1>
      </div>
      {!shouldShowTable && <span>Customer ID, start date or end date is invalid.</span>}
      {shouldShowTable && (
        <div className="shipping-page">
          <p className="shipping-page__customer-id">Customer ID: {customerNo}</p>
          <TableComponent isLoading={isTableLoading} data={shipments} columns={columns} onRowClick={handleRowClick} />
        </div>
      )}
    </AppLayout>
  );
};
