import React from 'react';
import { TableColumn, TableComponent } from '../../../components/Table/Table';
import { Dlvitm } from '../../../core/models/Dlvitm';

const columns: TableColumn<Dlvitm>[] = [
  {
    columnName: 'SKU',
    key: 'sku',
  },
  {
    columnName: 'Description',
    key: 'description',
    width: '240px',
  },
  {
    columnName: 'UPC1',
    key: 'upc1',
  },
  {
    columnName: 'UPC2',
    key: 'upc2',
  },
  {
    columnName: 'Qty Shipped',
    key: 'qtyShipped',
  },
  {
    columnName: 'Qty Delivered',
    key: 'qtyDelivered',
  },
  {
    columnName: 'Tote #',
    key: 'containerNo',
  },
  {
    columnName: 'Invoice #',
    key: 'invoiceNo',
  },
  {
    columnName: 'Discrepancy',
    key: 'discrepancy',
    renderColumn: (data): React.ReactNode => (
      <div className="form-check">
        <input
          className="form-check-input"
          readOnly
          checked={data.qtyDif !== 0}
          type="checkbox"
          id="flexCheckDefault"
        />
      </div>
    ),
  },
  {
    columnName: 'Container Type',
    key: 'containerType',
  },
];

type Props = {
  isLoading: boolean;
  data: Dlvitm[];
};

export const DriverManifestTable: React.FC<Props> = ({ isLoading, data }) => {
  return <TableComponent isLoading={isLoading} data={data} columns={columns} />;
};
