import { FingerprintAIO } from '@ionic-native/fingerprint-aio';
import { Dialogs } from '@ionic-native/dialogs';

import TokenStorageService from './TokenStorageService';
import AuthService from './AuthService';

const KEY = 'biometryPermission';

/** Service for usage biometry features (mobile only). */
const BiometricService = {
  /**
   * Open native modal to auth by biometry.
   *
   * @param {function} successCallback Function need to be run after successful auth.
   */
  useBiometric(successCallback): void {
    FingerprintAIO.isAvailable().then(result => {
      const token = TokenStorageService.get();

      if (!token) return;

      if (TokenStorageService.isExpired(token)) {
        AuthService.refreshToken().then(accessToken => {
          TokenStorageService.set(accessToken);

          showBiometric(result, successCallback);
        });
      } else {
        showBiometric(result, successCallback);
      }
    });
  },

  /** Return permission flag for using biometry.  */
  permissionGiven(): boolean | null {
    return JSON.parse(localStorage.getItem(KEY));
  },

  /** Set permission flag for using biometry to "true". */
  allowBiometry(): void {
    localStorage.setItem(KEY, JSON.stringify(true));
  },

  /** Set permission flag for using biometry to "false". */
  forbidBiometry(): void {
    localStorage.setItem(KEY, JSON.stringify(false));
  },

  /** Reset permission flag for using biometry. */
  resetPermission(): void {
    localStorage.setItem(KEY, JSON.stringify(null));
  },

  /**
   * Open dialog window of asking user to use biometry to for authentication.
   *
   * @param {String} biometryType Biometry type (face or finger).
   */
  openPermissionDialog(biometryType: string): void {
    let biometryTypeText = '';

    if (biometryType === 'finger') {
      biometryTypeText = 'TouchID';
    }

    if (biometryType === 'face') {
      biometryTypeText = 'FaceID';
    }

    Dialogs.confirm('', `Do you want to use ${biometryTypeText} for authentication?`, ['OK', "Don't allow"]).then(
      result => {
        if (result === 1) {
          // If "Allow".
          BiometricService.allowBiometry();
        } else if (result === 2) {
          // If "Disallow".
          BiometricService.forbidBiometry();
        } else if (result === 0) {
          BiometricService.resetPermission();
        }
      },
    );
  },
};

/**
 * Open native biometry dialog if there is permission to do it.
 *
 * @param type Biometry type
 * @param successCallback Function calling after successful biometry auth.
 */
const showBiometric = (type: string, successCallback: () => unknown): void => {
  if (!BiometricService.permissionGiven()) return;

  if (type === 'finger' || type === 'face') {
    FingerprintAIO.show({
      title: 'Core-Source-SSO',
      subtitle: 'Please authenticate',
      disableBackup: true,
      fallbackButtonTitle: 'Use Pin',
    }).then(() => successCallback());
  }
};

export default BiometricService;
