import React, { useState } from 'react';

import { deleteAccountModalId } from '../../pages/IndexPage/IndexPage';
import UserProfileService from '../../core/services/UserProfileService';
import BaseButton from '../BaseButton/BaseButton';
import './style.scss';

interface DeleteAccountModalProps {
  onLogout: () => void;
}

/** Delete account modal component. */
const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ onLogout }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Delete account.
   */
  const handleDeleteAccount = async (): Promise<void> => {
    try {
      setSubmitting(true);

      await UserProfileService.deleteAccount();
      $(`#${deleteAccountModalId}`).modal('hide');
      onLogout();
    } catch (error) {
      setError(error.response.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      className="modal fade delete-account-modal"
      id={deleteAccountModalId}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="deleteAccountModalLabel"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h4>
              Are you sure you want to delete your account? <br />
              <i>
                <small>Note: Once deleted, this cannot be undone.</small>
              </i>
            </h4>
            <div className="buttons">
              <BaseButton className="btn btn-lg btn-block" type="button" data-dismiss="modal">
                Cancel
              </BaseButton>
              <BaseButton
                className="btn btn-red btn-submit btn-lg btn-block"
                type="button"
                onClick={handleDeleteAccount}
                disabled={submitting}
              >
                Delete
              </BaseButton>
            </div>
            {error && <p className="error-msg">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
