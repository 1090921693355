import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import './styles.scss';
import LogoWhite from '../../components/Footer/FooterLogo/LogoWhite';
import ManualLoginForm from '../../components/AuthModal/ManualLoginForm/ManualLoginForm';
import UserProfileService from '../../core/services/UserProfileService';
import { LoginFormData } from '../../core/models';
import AuthService from '../../core/services/AuthService';

const AuthPageBodyStyles = `
  body {
    background-color: var(--color-green);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-top: 0;
    width: 100vw;
    overflow: hidden;
  }
`;

/** Auth page component. */
const AuthPage: React.FC = () => {
  const history = useHistory();

  /**
   * Handle login.
   */
  const handleLogin = async ({ email, password, rememberMe }: LoginFormData): Promise<void> => {
    await AuthService.login({ email, password, rememberMe });
    await UserProfileService.loadUserProfileData({
      anonymous: false,
    });

    history.push('/');
  };

  /** Effect calling after successful biometry auth. */
  const handleAfterBiometrySuccess = async (): Promise<void> => {
    await UserProfileService.loadUserProfileData({
      anonymous: false,
    });

    history.push('/');
  };

  return (
    <>
      <ReactNotification className="notificator" />
      <div className="ios-auth-form">
        <div className="logo-container">
          <LogoWhite />
        </div>
        <div className="text-center text-white">
          <span>Sign on to access your account</span>
          <hr className="separator" />
        </div>
        <ManualLoginForm handleAfterBiometrySuccess={handleAfterBiometrySuccess} handleLogin={handleLogin} />
        <style>{AuthPageBodyStyles}</style>
      </div>
    </>
  );
};

export default AuthPage;
