import React, { useEffect, useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './style.scss';

import BaseButton from '../../BaseButton/BaseButton';
import { posOptions } from '../shared/pos';
import { formValidation, initialFormState } from './formSettings';
import UserDetails from './../UserDetails/UserDetails';
import Tooltip from '../../Tooltip/Tooltip';
import { LicenceType } from '../../../shared/enum';
import { PDISolutionType } from './PDISolutionType';
import { AddonType } from './AddonType';

/**
 * Step 1 form component.
 */
export default function Step1({ availableDivisions, submitting, onSubmit }): JSX.Element {
  const methods = useForm({
    resolver: yupResolver(formValidation),
    defaultValues: initialFormState,
    shouldFocusError: false,
  });

  const values = methods.getValues();
  const [isSelectedPos, setSelectedPos] = useState(false);
  const [formData, setFormData] = useState(values);

  useEffect(() => {
    // Disabled submit button if Pos === Others.
    const subscription = methods.watch(value => {
      setSelectedPos(value.user[0].posSystem === posOptions[3].value);
    });

    return (): void => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.user, methods.watch]);

  const handleFormSubmit = (): void => {
    const pdiSolutionToEnrollIn = [];

    if (methods.getValues().scanData) {
      pdiSolutionToEnrollIn.push(PDISolutionType.CStoreEssentialsScanDataBasic);
    }

    if (methods.getValues().backOffice) {
      pdiSolutionToEnrollIn.push(PDISolutionType.CStoreEssentialsStoreOperationsStandard);
    }

    if (methods.getValues().topOffRewards) {
      pdiSolutionToEnrollIn.push(PDISolutionType.TopOffRewards);
    }

    methods.setValue('pdiSolutionToEnrollIn', pdiSolutionToEnrollIn);

    onSubmit(methods.getValues());
  };

  const handleFormChange = (): void => {
    setFormData(methods.getValues());
  };

  const handleDataBasicChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    methods.setValue('scanData', checked);

    const values = methods.getValues();

    if (checked && !values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.ScandataOnly);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (!checked && values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithScandata);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (checked && values.backOffice && values.topOffRewards) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (!checked && values.backOffice && values.topOffRewards) {
      methods.setValue('user.0.plan', LicenceType.ScandataWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (checked && !values.backOffice && values.topOffRewards) {
      methods.setValue('user.0.plan', LicenceType.ScandataWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (!checked && !values.backOffice && !values.topOffRewards) {
      methods.setValue('user.0.plan', null);
      methods.setValue('user.0.addon', AddonType.None);
    }
  };

  const handleOperationsStandardsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    methods.setValue('backOffice', checked);

    const values = methods.getValues();

    if (checked && !values.scanData) {
      methods.setValue('scanData', true);
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithScandata);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (!checked && values.scanData) {
      methods.setValue('user.0.plan', LicenceType.ScandataOnly);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (checked && values.scanData) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithScandata);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (checked && values.scanData && values.topOffRewards) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (!checked && values.scanData && values.topOffRewards) {
      methods.setValue('user.0.plan', LicenceType.ScandataWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (!checked && !values.scanData && !values.topOffRewards) {
      methods.setValue('user.0.plan', null);
      methods.setValue('user.0.addon', AddonType.None);
    }
  };

  const handleTopOffChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    methods.setValue('topOffRewards', checked);

    const values = methods.getValues();

    if (checked) {
      methods.setValue('user.0.plan', LicenceType.ScandataWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (!checked && values.scanData && values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithScandata);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (!checked && !values.scanData && values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithScandata);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (!checked && values.scanData && !values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.ScandataOnly);
      methods.setValue('user.0.addon', AddonType.None);
    }

    if (checked && values.scanData && values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.StoreOpsWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (checked && !values.scanData && !values.backOffice) {
      methods.setValue('user.0.plan', LicenceType.ScandataWithLoyalty);
      methods.setValue('user.0.addon', AddonType.TopOff);
    }

    if (!checked && !values.scanData && !values.backOffice) {
      methods.setValue('user.0.plan', null);
      methods.setValue('user.0.addon', AddonType.None);
    }
  };

  const isDisabledSubmit = isSelectedPos || submitting;
  const isTopOoRewards = values.topOffRewards && !values.scanData && !values.backOffice;

  return (
    <FormProvider {...methods}>
      <h2 className="text-center">PDI Solutions Sign Up</h2>
      <div className="form-notes">
        <p>
          <span className="red">*</span>These fields are mandatory to complete.
        </p>
      </div>
      <form
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        onChange={handleFormChange}
        className="cse-step1"
        noValidate
      >
        <UserDetails availableDivisions={availableDivisions} data={[{ ...formData.user[0] }]} />

        <div className="row">
          <div className="col-md-12">
            <fieldset
              className={classNames('custom-fieldset', {
                invalid: (methods.formState.errors as any)?.user && (methods.formState.errors as any)?.user[0].plan,
              })}
            >
              <legend>Select which PDI solutions you’ve enrolled in</legend>
              <div className="pdi-solutions-checkbox">
                <label className="checkbox">
                  <input {...methods.register('scanData')} type="checkbox" onChange={handleDataBasicChange} />
                  C-Store Essentials Scan Data Basic
                </label>
                <label className="checkbox">
                  <input
                    {...methods.register('backOffice')}
                    type="checkbox"
                    onChange={handleOperationsStandardsChange}
                  />
                  C-Store Essentials Store Operations Standard
                </label>
                <label className="checkbox">
                  <input {...methods.register('topOffRewards')} type="checkbox" onChange={handleTopOffChange} />
                  Top Off Rewards
                </label>
              </div>
            </fieldset>
            {isTopOoRewards && (
              <Tooltip alwaysShow>
                CStore Essentials (CSE) is a necessary agent for the Top-Off Rewards program. You will not have full
                access or be billed for CSE.*
              </Tooltip>
            )}
          </div>
        </div>

        {isTopOoRewards && (
          <div className="row">
            <div className="col-md-12">
              <fieldset className="custom-fieldset">
                <legend>Please enter your CSE number if you&apos;ve already enrolled</legend>
                <div className="pdi-solutions-checkbox">
                  <label>
                    CSE Number:
                    <input
                      {...methods.register('cseNumber')}
                      className={classNames('line-input', {
                        invalid: methods.formState.errors?.cseNumber,
                      })}
                    />
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
        )}

        <BaseButton
          className="btn btn-orange btn-lg btn-block"
          type="submit"
          disabled={isDisabledSubmit}
          loading={submitting}
        >
          Next
        </BaseButton>
      </form>
    </FormProvider>
  );
}
