import React from 'react';

import './style.scss';
import '../../../styles/base/_helper.scss';

/** Socials section in <Footer />. */
const FooterSocials: React.FC = () => {
  return (
    <div className="social-media">
      <h6 className="text-uppercase font-normal color-white">FOLLOW US!</h6>
      <ul>
        <li>
          <a href="https://www.instagram.com/coremarkinternational/" rel="noopener noreferrer" target="_blank">
            <img src="/assets/img/general/social-icons/ic-instagram.svg" alt="Instagram logo" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/CoreMarkHQ" rel="noopener noreferrer" target="_blank">
            <img src="/assets/img/general/social-icons/ic-twitter.svg" alt="Instagram logo" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/coremarkinternational" rel="noopener noreferrer" target="_blank">
            <img src="/assets/img/general/social-icons/ic-facebook.svg" alt="Instagram logo" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocials;
