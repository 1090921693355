/** Category link model. */
export class CategoryLink {
  /** Category link id. */
  public id: number;

  /** Category link visible name. */
  public name: string;

  /** Category link path. */
  public href: string;

  /**
   * CategoryLink model constructor.
   *
   * @param data CategoryLink instance data.
   */
  public constructor(data: CategoryLink) {
    this.id = data.id;
    this.name = data.name;
    this.href = data.href;
  }
}
