export const initialUserState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  storeName: '',
  accountType: 'SSO',
  plan: null,
  addon: '',
  fuelBrand: 'Other',
  street: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
  posSystem: '',
  accountNumber: '',
  brandMetaData: {
    coreMarkDivisionName: [],
    coreMarkAccountId: '',
    additionalProp3: 'Retailer',
  },
  userName: '',
  password: '',
  confirmPassword: '',
};
