import React, { useContext } from 'react';
import classNames from 'classnames';

import './style.scss';

import FooterSocials from './FooterSocials/FooterSocials';
import LogoWhite from './FooterLogo/LogoWhite';
import { deleteAccountModalId } from '../../pages/IndexPage/IndexPage';
import { UserContext } from '../../App';

/** Root footer component. */
const Footer: React.FC = () => {
  const userContext = useContext(UserContext);
  const user = userContext.data;
  const colSocialClass = user ? 'col-md-offset-3' : 'col-md-offset-5';

  return (
    <footer>
      <div className="container">
        <div className="row footer-content">
          <div className="col-md-5 col-md-offset-1 col-sm-5 col-sm-offset-1">
            <LogoWhite />
          </div>
          {user && (
            <div className="col-md-2 col-sm-2 col-delete-account">
              <button
                className="btn btn-link color-white btn-xs"
                type="button"
                data-toggle="modal"
                data-target={`#${deleteAccountModalId}`}
              >
                Delete Account
              </button>
            </div>
          )}
          <div className={classNames('col-md-3 col-sm-4 col-sm-offset-3', colSocialClass)}>
            <FooterSocials />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
