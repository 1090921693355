import moment from 'moment';
import { CoreMarkShipmentDetailDto } from '../dto/CoreMarkShipmentDetailDto';
import { CoreMarkShipmentDto } from '../dto/CoreMarkShipmentDto';
import { CoreMarkShipment } from '../models/CoreMarkShipment';
import { CoreMarkShipmentDetail } from '../models/CoreMarkShipmentDetail';
import { Dlvitm } from '../models/Dlvitm';
import { Tote } from '../models/Tote';
import { axiosInstance } from './ApiService';
import { toExportBlobType } from '../helpers/convertToExportType';
import { Credit } from '../models/Credit';
import { Pickup } from '../models/Pickup';

export type ShipmentsFilterParams = {
  customerNo: string;
  shipFrom: string;
  shipTo: string;
};

export type ShipmentsPdfParams = {
  documentNo: string;
  stageSeq: string;
};

/** Core Mark service. */
const CoreMarkService = {
  /**
   * Get customer list.
   */
  async getShipments(filterParams: ShipmentsFilterParams): Promise<CoreMarkShipment[]> {
    const result = await axiosInstance.get('/core-mark/shipments-info', {
      params: filterParams,
    });

    // This is test data for shipment list. Keep it here for future testing.
    // const result = await axiosInstance.get('/core-mark/shipments-info?customerNo=9491&shipFrom=01/16/2024&shipTo=01/20/2024', {
    //   params: filterParams,
    // });

    return result.data.d.results.map((dto: CoreMarkShipmentDto) => {
      return new CoreMarkShipment({
        customerNo: dto.CustomerNo,
        shipmentNo: dto.ShipmentNo,
        shipFromDate: dto.Shipfromdate,
        shipToDate: dto.Shiptodate,
        seqNo: dto.SeqNo,
        shipDate: dto.Shipdate,
        route: dto.Route,
        containersShippedQty: Number(dto.ContainersShipedQty),
        casesShippedQty: Number(dto.CasesShipedQty),
        piecesShippedQty: Number(dto.PiecesShipedQty),
        emptyContainersRetQty: Number(dto.EmptyContainersRetQty),
        piecesShortedQty: Number(dto.PiecesShortedQty),
        itemsCreditedQty: Number(dto.ItemsCreditedQty),
        itemsPickedQty: Number(dto.ItemsPickedQty),
        signedBy: dto.SignedBy,
      });
    });
  },

  /**
   * Get shipment detail.
   */
  async getShipmentDetail(code: CoreMarkShipment['shipmentNo'], seqNo: string): Promise<CoreMarkShipmentDetail> {
    const result = await axiosInstance.get(`/core-mark/driver-manifest`, {
      params: {
        expand: 'Dlvitms,Crdts,Pickups,Totes',
        shipNumber: code,
        stageNumber: seqNo,
      },
    });
    return result.data.d.results.map((dto: CoreMarkShipmentDetailDto) => {
      return new CoreMarkShipmentDetail({
        shipNo: dto.ShipNo,
        stageNo: dto.StageNo,
        route: dto.Route,
        deliveryDate: dto.DeliveryDate ? moment(dto.DeliveryDate).toDate() : null,
        acceptedBy: dto.AcceptedBy,
        piecesDelivered: dto.PiecesDelivered,
        itemsCredited: dto.ItemsCredited,
        piecesShorted: dto.PiecesShorted,
        emptyContainersReturned: dto.EmptyContainersReturned,
        driverPickups: dto.DriverPickups,
        freezerTemp: dto.FreezerTemp,
        coolerTemp: dto.CoolerTemp,
        dlvitms: dto.Dlvitms.results.map(
          dlvitmDto =>
            new Dlvitm({
              sku: dlvitmDto.SKU,
              description: dlvitmDto.Discription,
              upc1: dlvitmDto.UPC1,
              upc2: dlvitmDto.UPC2,
              qtyShipped: Number(dlvitmDto.QtyShipped),
              qtyDelivered: Number(dlvitmDto.Qtydelivered),
              qtyDif: Number(dlvitmDto.Qtydif),
              containerNo: dlvitmDto.ContainerNo,
              invoiceNo: dlvitmDto.InvoiceNo,
              containerType: dlvitmDto.ContainerType,
            }),
        ),
        crdts: dto.Crdts.results.map(
          credit =>
            new Credit({
              sku: credit.CrdtSKU,
              description: credit.CrdtDiscription,
              upc1: credit.CrdtUPC1,
              upc2: credit.CrdtUPC2,
              qtyCredited: Number(credit.QtyCredited),
              container: credit.CrdtContainer,
              invoice: credit.CrdtInvoice,
              reason: credit.CreditReason,
            }),
        ),
        pickups: dto.Pickups.results.map(
          pickup =>
            new Pickup({
              no: pickup.PickupNo,
              sku: pickup.PickSKU,
              description: pickup.PickDiscription,
              upc1: pickup.PickUPC1,
              upc2: pickup.PickUPC2,
              qtyToPick: Number(pickup.QtyToPick),
              qtyPicket: Number(pickup.QtyPicked),
              reason: pickup.PickReason,
            }),
        ),
        totes: dto.Totes.results.map(
          toteDto =>
            new Tote({
              containerType: toteDto.ContainerType,
              qtyAllocated: Number(toteDto.QtyAllocated),
              qtyReturned: Number(toteDto.QtyReturned),
            }),
        ),
      });
    });
  },

  /**
   * Export pdf file.
   */
  async exportDetail(filterParams: ShipmentsPdfParams): Promise<Blob> {
    const result = await axiosInstance.get('/core-mark/pdf', {
      params: filterParams,
      responseType: 'blob',
    });
    return toExportBlobType(result.data);
  },
};

export default CoreMarkService;
