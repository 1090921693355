/**
 * Transform object errors to string.
 * @param errors Object errors.
 *
 * @returns The multiple errors string.
 */
export const transformObjectErrors = (errors: unknown): string => {
  if (errors instanceof Object) {
    const result: string[] = [];

    Object.values(errors).forEach(value => result.push(value.join('\n')));

    return result
      .filter(error => Boolean(error))
      .join('\n')
      .toString();
  }

  return '';
};
