import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import './style.scss';

import BaseButton from '../../BaseButton/BaseButton';
import UserDetailsPreview from './../UserDetailsPreview/UserDetailsPreview';
/**
 * Step 4 form component.
 */
export default function Step4({ initialValues, availableDivisions, isCombined, error, onSubmit }): JSX.Element {
  const [isVisibleEssentials, setVisibleEssentials] = useState(true);
  const [isVisitedAll, setVisitedAll] = useState(false);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    if (initialValues.scanData || initialValues.backOffice) {
      setVisibleEssentials(false);
    }
  }, [initialValues.scanData, initialValues.backOffice]);

  useEffect(() => {
    if (initialValues.cseNumber) {
      setDisabled(false);
    } else {
      setDisabled(!isVisitedAll);
    }
  }, [initialValues.cseNumber, isVisitedAll]);

  const handleFormSubmit = (): void => {
    onSubmit();
  };

  const handleCSEInstallClick = (): void => {
    setVisitedAll(true);
  };

  return (
    <div className="cse-step4">
      <h2 className="text-center">{isCombined ? 'Summary' : 'Top-Off Rewards'}</h2>
      <UserDetailsPreview
        data={initialValues.user}
        availableDivisions={availableDivisions}
        onCSEInstallClick={handleCSEInstallClick}
      />

      {isVisibleEssentials && (
        <>
          <div className="row">
            <div className="col-md-12">
              <fieldset className="custom-fieldset">
                <legend>C-Store Essentials</legend>
                <h5 className="text-center">What are you signing up for? Select all that applies.</h5>
                <div className="row-checkboxes">
                  <label className="checkbox">
                    <input type="checkbox" checked={initialValues.scanData} readOnly />
                    Scan Data
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" checked={initialValues.backOffice} readOnly />
                    Back Office
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" checked={initialValues.topOffRewards} readOnly />
                    Top-Off only
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="checkbox">
                <input type="checkbox" checked={initialValues.iHavePC} readOnly />I have a stationary windows PC on site
                that is connected to my back office
              </label>
            </div>
          </div>
        </>
      )}

      <div className="row">
        <div className="col-md-12">
          <span className="error-message">{error}</span>
        </div>
      </div>

      <BaseButton
        className={classNames('btn btn-orange btn-submit btn-lg btn-block btn-multiline', {
          'btn-visited': isDisabled,
        })}
        type="button"
        disabled={isDisabled}
        onClick={handleFormSubmit}
      >
        All installation(s) are scheduled! I’m done!
      </BaseButton>
    </div>
  );
}
