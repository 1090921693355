/** Application settings model. */
export class ApplicationSettings {
  /** Banner. */
  banner: {
    /** Is banner displayed. */
    isBannerDisplayed: boolean;

    /** Banner text. */
    bannerText: string;
  };

  /** Is request access enabled. */
  isRequestAccessEnabled: boolean;

  /** Is CCTF receipt icon displayed. */
  isCCTFReceiptsIconDisplayed: boolean;

  /**
   * ApplicationSettings model constructor.
   *
   * @param data ApplicationSettings data.
   */
  public constructor(data: ApplicationSettings) {
    this.banner = data.banner;
    this.isRequestAccessEnabled = data.isRequestAccessEnabled;
    this.isCCTFReceiptsIconDisplayed = data.isCCTFReceiptsIconDisplayed;
  }
}
