import React from 'react';

import './style.scss';

import MenuButton from './MenuButton/MenuButton';

/** Header brand component. */
const HeaderBrand: React.FC = () => {
  return (
    <div className="navbar-header">
      <div className="justify-header">
        <a className="navbar-brand" rel="noopener noreferrer" target="_blank" href="https://www.core-mark.com/">
          <img className="img-responsive header-logo" src="/assets/img/general/logo.png" alt="Core Source SOO logo" />
        </a>
        <MenuButton />
      </div>
    </div>
  );
};

export default HeaderBrand;
