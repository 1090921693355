import React from 'react';

import './style.scss';

import MainSliderElement from '../MainSliderElement/MainSliderElement';
import { User } from '../../../core/models';
import { signOnModalId } from '../../../pages/IndexPage/IndexPage';
import DataLoader from '../../DataLoader/DataLoader';
import { REQUEST_ACCESS_MODAL_ID } from '../../RequestAccessDialog/RequestAccessDialog';

interface MainSliderWelcomeSlideProps {
  isRequestAccessEnabled: boolean;
  user: User | null;
  handleLogout: () => void;
  profileLoading: boolean;
}

/**
 * Welcome slide component.
 *
 * @param props.supportMail Mail for sending support access request.
 * @param props.user Data about authed or unauthed user.
 */
const MainSliderWelcomeSlide: React.FC<MainSliderWelcomeSlideProps> = props => {
  const { user, isRequestAccessEnabled, profileLoading, handleLogout } = props;

  const renderSliderButtons = user ? (
    <button className="btn btn-white btn-lg" type="button" onClick={handleLogout}>
      Logout
    </button>
  ) : (
    <>
      <button className="btn btn-white btn-lg" type="button" data-toggle="modal" data-target={`#${signOnModalId}`}>
        Sign On
      </button>
      {isRequestAccessEnabled && (
        <button
          className="btn btn-white-border btn-lg"
          type="button"
          data-toggle="modal"
          data-target={`#${REQUEST_ACCESS_MODAL_ID}`}
        >
          Request access
        </button>
      )}
    </>
  );

  const renderGreetingContent =
    user && user.firstName ? (
      <i>Welcome {user.firstName}!</i>
    ) : (
      <span className="login-title">Log in to access your customized portal</span>
    );

  const renderGreeting = <h3 className="welcome-user">{renderGreetingContent}</h3>;

  const renderWelcomeContent = profileLoading ? (
    <DataLoader size={100} color="#fff" />
  ) : (
    <>
      <div className="welcome-content">
        <img className="img-responsive center-block" src="/assets/img/content/logo-source.png" alt="Core logo" />
        <h2 className="title-one-stop">
          One-stop shop to the <u>future</u> of convenience today!
        </h2>
        {renderGreeting}
      </div>
      <div className="welcome-buttons">{renderSliderButtons}</div>
    </>
  );

  return (
    <MainSliderElement>
      <img
        className="img-responsive center-block slider-image"
        src="/assets/img/content/track.jpg"
        alt="Main slider track"
      />
      {renderWelcomeContent}
    </MainSliderElement>
  );
};

export default MainSliderWelcomeSlide;
