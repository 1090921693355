import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import TokenStorageService from './TokenStorageService';
import NotificationService from './NotificationService';
import AuthService from './AuthService';
import PDISessionStorageService from './PDISessionStorageService';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 60 * 1000,
});

/** Axios request interceptor. */
axiosInstance.interceptors.request.use(request => requestHandler(request));

/** Axios response interceptor. */
axiosInstance.interceptors.response.use(...Array(1), error => errorHandler(error));

const axiosPDIAuthInstance = axios.create({
  baseURL: `${process.env.REACT_APP_PDI_AUTH_API_URL}`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 60 * 1000,
});

/** Axios Auth PDI request interceptor. */
axiosPDIAuthInstance.interceptors.request.use(request => requestHandler(request));

/** Axios Auth PDI response interceptor. */
axiosPDIAuthInstance.interceptors.response.use(...Array(1), error => errorHandler(error));

const axiosPDIInstance = axios.create({
  baseURL: `${process.env.REACT_APP_PDI_API_URL}`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 60 * 1000,
});

/** Axios PDI request interceptor. */
axiosPDIInstance.interceptors.request.use(request => {
  request.headers['Authorization'] = `Bearer ${PDISessionStorageService.getSessionToken()}`;

  return request;
});

/** Axios PDI response interceptor. */
axiosPDIInstance.interceptors.response.use(...Array(1), error => errorHandler(error));

/**
 * API request interceptor.
 *
 * @param request Axios request.
 */
const requestHandler = (request: AxiosRequestConfig): AxiosRequestConfig => {
  if (request.url && !request.url.includes('auth')) {
    if (request.headers['Anonymous'] !== true) {
      request.headers['Authorization'] = `Bearer ${TokenStorageService.get()}`;
    }
  }

  return request;
};

let refreshTokenRequest: Promise<string> | null = null;

/**
 * API error interceptor.
 *
 * @param error Axios error.
 */
const errorHandler = (error: AxiosError): Promise<AxiosError> | Promise<unknown> => {
  const { config, response, code } = error;
  const originalRequest = config;
  const status = response && response.status;

  if (status === 401) {
    if (!refreshTokenRequest) {
      refreshTokenRequest = AuthService.refreshToken();
    }

    // Retry original request after refresh token.
    return refreshTokenRequest
      .then(accessToken => {
        TokenStorageService.set(accessToken);
        refreshTokenRequest = null;
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axios(originalRequest);
      })
      .catch(() => {
        AuthService.logout();

        if (status !== 401) {
          NotificationService.push('warning', 'Network error.', 'Please try to refresh the page.');
        }

        return Promise.reject(error);
      });
  }

  /** Network error. */
  if (status === 501 || status === 500) {
    NotificationService.push('warning', 'Network error.', 'Please try to refresh the page.');
  }

  /** Timeout fallback. */
  if (code === 'ECONNABORTED') {
    NotificationService.push('warning', 'Server is not responding.', 'Please try again later.');
  }

  /** Authentication error. */
  if (status === 400) {
    NotificationService.push('warning', 'Authentication error.', error.response.data.title);
  }

  return Promise.reject(error);
};

export { axiosInstance, axiosPDIAuthInstance, axiosPDIInstance };
