import { AxiosResponse } from 'axios';
import { axiosPDIInstance, axiosPDIAuthInstance } from './ApiService';
import PDISessionStorageService from './PDISessionStorageService';
import { CSESignup } from '../models/CSESignup';
import { CseSignupDto } from '../dto/CseSignupDto';
import { PDILoginDto } from '../dto/PDILoginDto';
import { PDILogin } from '../models/PDILogin';
import { PDISignupResponse } from '../models/PDISignupResponse';

/** Based on PDI service. */
const PDIService = {
  /**
   * Login PDI user.
   */
  async loginPDI(): Promise<PDILogin> {
    const payload = {
      username: process.env.REACT_APP_PDI_USERNAME,
      password: process.env.REACT_APP_PDI_PASSWORD,
    };

    const { data: responseData } = await axiosPDIAuthInstance.post<AxiosResponse<PDILoginDto>>(
      '/Users/authenticate',
      payload,
    );

    const response = mapToLogin(responseData.data);

    PDISessionStorageService.setSessionToken(response.jwtToken);

    return response;
  },

  /**
   * Signup PDI.
   *
   * @param data CSE form data.
   */
  async signupPDI(data: CSESignup): Promise<AxiosResponse<PDISignupResponse>> {
    const dto = mapToSignup(data);

    const { data: responseData } = await axiosPDIInstance.post('/Signup', dto);

    return responseData.data;
  },
};

/**
 * Login mapper function from DTO to model.
 *
 * @param pdiLogin Login instance.
 */
const mapToLogin = (pdiLogin: PDILoginDto): PDILogin => {
  return new PDILogin({
    id: pdiLogin.id,
    firstName: pdiLogin.firstName,
    lastName: pdiLogin.lastName,
    username: pdiLogin.Username,
    jwtToken: pdiLogin.JwtToken,
    refreshToken: pdiLogin.RefreshToken,
  });
};

/**
 * User mapper function from DTO to model.
 *
 * @param cseSignup Cse signup instance.
 */
const mapToSignup = (cseSignup: CSESignup): CseSignupDto => {
  return {
    store: {
      storeName: cseSignup.store.storeName,
      email: cseSignup.store.email,
      phone: cseSignup.store.phone,
      accountType: cseSignup.store.accountType,
      fuelBrand: cseSignup.store.fuelBrand,
      plan: cseSignup.store.plan,
      addon: cseSignup.store.addon,
      street: cseSignup.store.street,
      city: cseSignup.store.city,
      state: cseSignup.store.state,
      zip: cseSignup.store.zip,
      country: cseSignup.store.country,
      brandMetaData: {
        coreMarkDivisionName: cseSignup.store.brandMetaData.coreMarkDivisionName,
        coreMarkAccountId: cseSignup.store.brandMetaData.coreMarkAccountId,
        additionalProp3: cseSignup.store.brandMetaData.additionalProp3,
      },
    },
    user: {
      firstName: cseSignup.user.firstName,
      lastName: cseSignup.user.lastName,
      password: cseSignup.user.password,
      userName: cseSignup.user.userName,
    },
  };
};

export default PDIService;
