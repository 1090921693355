import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BaseButton from '../../../components/BaseButton/BaseButton';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import { downloadFile } from '../../../core/helpers/downloadFile';
import { formatDegrees } from '../../../core/helpers/formatDegrees';
import { useSearchParam } from '../../../core/hooks/useSearchParam';
import { CoreMarkShipmentDetail } from '../../../core/models/CoreMarkShipmentDetail';
import CoreMarkService from '../../../core/services/CoreMarkService';
import { DriverCreditsTable } from './DriverCreditsTable';
import { DriverEmptyContainersReturnTable } from './DriverEmptyContainersReturnTable';
import { DriverManifestTable } from './DriverManifestTable';
import { DriverPickupsTable } from './DriverPickupsTable';
import './style.scss';

const ShippingSummaryItem: React.FC<{ title: string; content: ReactNode }> = ({ title, content }) => {
  return (
    <div className="item">
      <b>{title}</b>
      <span>{content ? content : '-'}</span>
    </div>
  );
};

const ShippingDetailContent: React.FC<{ isLoading: boolean; shipment: CoreMarkShipmentDetail | null }> = ({
  isLoading,
  shipment,
}) => {
  const searchParams = useSearchParam();
  const customerNo = searchParams.get('customer-no');

  if (isLoading) {
    return <p>Page is loading ...</p>;
  }

  if (shipment == null) {
    return <div>Invalid shipment info.</div>;
  }

  return (
    <div className="shipping-detail-content">
      <div className="shipping-detail-content__summary">
        <div className="items items__with-borders">
          <ShippingSummaryItem title="Route #, Sequence #" content={`${shipment.route}, ${shipment.stageNo}`} />
          <ShippingSummaryItem title="Location Code" content={customerNo} />
          <ShippingSummaryItem
            title="Delivery Date"
            content={shipment.deliveryDate ? moment(shipment.deliveryDate).format('MM/DD/YYYY') : null}
          />
          <ShippingSummaryItem title="Accepted By" content={shipment.acceptedBy} />
        </div>
        <div className="items items__with-borders">
          <ShippingSummaryItem title="# of Pieces Delivered" content={shipment.piecesDelivered} />
          <ShippingSummaryItem title="# of Items Credited" content={shipment.itemsCredited} />
          <ShippingSummaryItem title="# of Pieces Shorted" content={shipment.piecesShorted} />
        </div>
        <div className="items">
          <ShippingSummaryItem title="# of Empty Containers Returned" content={shipment.emptyContainersReturned} />
          <ShippingSummaryItem title="# of Driver Pickups" content={shipment.pickups.length} />
          <ShippingSummaryItem title="Freezer Temperature" content={formatDegrees(Number(shipment.freezerTemp))} />
          <ShippingSummaryItem title="Cooler Temperature" content={formatDegrees(Number(shipment.coolerTemp))} />
        </div>
      </div>
      <DriverManifestTable data={shipment.dlvitms} isLoading={isLoading} />
      <DriverCreditsTable data={shipment.crdts} isLoading={isLoading} />
      <DriverPickupsTable data={shipment.pickups} isLoading={isLoading} />
      <DriverEmptyContainersReturnTable data={shipment.totes} isLoading={isLoading} />
    </div>
  );
};

export const ShippingDetailPage: React.FC = () => {
  const params = useParams<{ code: string }>();
  const [shipment, setShipment] = useState<CoreMarkShipmentDetail | null>(null);
  const searchParams = useSearchParam();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const seqNo = searchParams.get('seq-no');

  const fetchShipmentDetail = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const shipment = await CoreMarkService.getShipmentDetail(params.code, seqNo);
      setShipment(shipment[0] ?? null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportShippingInfo = async (): Promise<void> => {
    setIsExporting(true);
    try {
      const blob = await CoreMarkService.exportDetail({ stageSeq: shipment.stageNo, documentNo: shipment.shipNo });
      downloadFile(blob, `Detailed E-Receipt No. ${shipment.shipNo}`);
    } finally {
      setIsExporting(false);
    }
  };

  const handleNavigateBack = (): void => {
    history.goBack();
  };

  useEffect(() => {
    fetchShipmentDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppLayout>
      <div className="shipping-detail__title">
        <div className="shipping-detail__heading-wrapper">
          <button onClick={handleNavigateBack} className="shipping-page__back-button">
            <img className="shipping-page__back-button-icon" src="/assets/img/svg/ic-back.svg" alt="Back button" />
          </button>
          <h1 className="shipping-page__heading">Driver manifests</h1>
        </div>
        <BaseButton
          loading={isExporting}
          disabled={isLoading}
          onClick={handleExportShippingInfo}
          type="button"
          className="btn btn-orange"
        >
          DETAILED E-RECEIPT
        </BaseButton>
      </div>
      <div className="shipping-detail__summary">
        <h2>Summary</h2>
        <ShippingDetailContent isLoading={isLoading} shipment={shipment} />
      </div>
    </AppLayout>
  );
};
