import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';

import './style.scss';

import { Category, CategoryLink } from '../../../core/models';
import useMobileWidth from '../../../core/hooks/useMobileWidth';

interface CategoriesElementProps {
  category: Category;
}

/**
 * Element of categories list component.
 *
 * @param props.category.id Category id.
 * @param props.category.name Category name.
 * @param props.category.color Category own color.
 * @param props.category.headerImageUrl Path on category face image.
 * @param props.category.centralImageUrl Path on category card background image.
 * @param props.category.menuItems List of resources available for current category.
 * @param props.category.isAccessible Category disabled flag.
 */
const CategoriesElement: React.FC<CategoriesElementProps> = props => {
  const {
    category: { id, name, color, headerImageUrl, centralImageUrl, menuItems, isAccessible = false },
  } = props;

  /** Open collapse panel state. */
  const [opened, setOpened] = useState<boolean>(false);

  const isMobileWidth: boolean = useMobileWidth();

  /** Toggle open collapse panel state. */
  const toggleOpenedState = (): void => {
    isAccessible && setOpened(prevOpened => !prevOpened);
  };

  const categoryItemClassNames: string = classNames('category-item', { disabled: !isAccessible });

  const categoryFlipInnerClassNames: string = classNames('category-flip-inner', {
    opened,
  });

  const renderResourcesLinks =
    menuItems &&
    menuItems.map((link: CategoryLink) => {
      if (link.name === 'PDI Solutions Sign Up') {
        const url = new URL(link.href);

        return (
          <li key={link.id}>
            <Link to={url.pathname} style={{ borderLeftColor: color }}>
              {link.name}
            </Link>
          </li>
        );
      }

      return (
        <li key={link.id}>
          <a href={link.href} rel="noopener noreferrer" target="_blank" style={{ borderLeftColor: color }}>
            {link.name}
          </a>
        </li>
      );
    });

  const renderLogoPlaceholder = !isMobileWidth && (
    <div className="category-logo-placeholder" style={{ backgroundColor: color }}>
      <img className="img-responsive" src={headerImageUrl} alt={name} />
    </div>
  );

  const renderBackFlip = (
    <div className="category-flip-back" style={{ backgroundColor: color }}>
      {renderLogoPlaceholder}
      <h3>{name}</h3>
      <ul className="list-unstyled">{renderResourcesLinks}</ul>
    </div>
  );

  const renderCollapseList = isMobileWidth ? <Collapse isOpen={opened}>{renderBackFlip}</Collapse> : renderBackFlip;

  return (
    <div className="col-md-4 col-xs-12">
      <div className={categoryItemClassNames}>
        <div className={categoryFlipInnerClassNames}>
          <div
            className="category-flip-front"
            data-toggle="collapse"
            data-target={`#category-${id}`}
            aria-expanded={opened}
            aria-controls={`category-${id}`}
            onClick={toggleOpenedState}
          >
            <div className="category-background" style={{ backgroundColor: color }} />
            <div className="logo-border" style={{ borderColor: color }} />
            <div className="category-logo">
              <img className="img-responsive" src={headerImageUrl} alt={name} />
            </div>
            <img className="img-responsive category-image" src={centralImageUrl} alt={`${name} category`} />
            <div className="category-info">
              <h4 className="category-name">{name}</h4>
            </div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="btn-toggle"
              data-toggle="collapse"
              data-target={`#category-${id}`}
              aria-expanded={opened}
              aria-controls={`category-${id}`}
            >
              <div className="triangle" />
            </a>
          </div>
          {renderCollapseList}
        </div>
      </div>
    </div>
  );
};

export default CategoriesElement;
