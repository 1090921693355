import React from 'react';

import './style.scss';

import { Category } from '../../core/models';
import CategoriesElement from './CategoriesElement/CategoriesElement';

interface CategoriesProps {
  categories: Category[] | null;
}

/**
 * Root categories component.
 *
 * @param props.categories List of categories need to render.
 */
const Categories: React.FC<CategoriesProps> = props => {
  const { categories } = props;

  const renderCategoriesList =
    categories && categories.map(category => <CategoriesElement key={category.id} category={category} />);

  return (
    <div className="block-categories">
      <div className="category-list">
        <div className="row">{renderCategoriesList}</div>
      </div>
    </div>
  );
};

export default Categories;
