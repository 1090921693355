import React from 'react';

import BaseButton from '../../BaseButton/BaseButton';
import './style.scss';

/**
 * Confirm close modal component.
 */
export default function ConfirmCloseModal({ id, onCancel, onClose }): JSX.Element {
  return (
    <div
      className="modal fade confirm-close-modal"
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmCloseModalLabel"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <h4>
                You are about closing the PDI Solutions form. All data will be lost. Are you sure you want to close the
                form?
              </h4>
              <hr className="separator" />
            </div>
            <div className="buttons">
              <BaseButton className="btn btn-lg btn-block" type="button" onClick={onCancel}>
                Cancel
              </BaseButton>
              <BaseButton className="btn btn-orange btn-submit btn-lg btn-block" type="button" onClick={onClose}>
                Close
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
