export class Tote {
  /** Container type. */
  public containerType: string | null;

  /** Quantity allocated. */
  public qtyAllocated: number | null;

  /** Quantity returned. */
  public qtyReturned: number | null;

  public constructor(data: Tote) {
    this.containerType = data.containerType;
    this.qtyAllocated = data.qtyAllocated;
    this.qtyReturned = data.qtyReturned;
  }
}
