import React, { useState, useEffect, useContext } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio';
import { useAppState } from '@capacitor-community/app-react';

import { MainSlider, AuthModal, Categories, DeleteAccountModal } from '../../components';
import { Category, LoginFormData, Division, ApplicationSettings } from '../../core/models';
import UserProfileService from '../../core/services/UserProfileService';
import ContactUs from '../../components/ContactUs/ContactUs';
import AppBanner from '../../components/AppBanner/AppBanner';
import AuthService from '../../core/services/AuthService';
import { closeAuthModal } from '../../core/helpers/closeAuthModal';
import PlatformService from '../../core/services/PlatformService';
import DataLoader from '../../components/DataLoader/DataLoader';
import AppLayout from '../../containers/AppLayout/AppLayout';
import { UserContext } from '../../App';
import BiometricService from '../../core/services/BiometricService';
import useBiometry from '../../core/hooks/useBiometric';
import { SSOService } from '../../core/services/SSOService';
import TokenStorageService from '../../core/services/TokenStorageService';
import RequestAccessDialog, { REQUEST_ACCESS_MODAL_ID } from '../../components/RequestAccessDialog/RequestAccessDialog';
import CSEDialog, { CSE_MODAL_ID } from '../../components/CSEDialog/CSEDialog';
import NotificationService from '../../core/services/NotificationService';

export const signOnModalId = 'signOnModal';
export const deleteAccountModalId = 'deleteAccountModal';

/** Root page component. */
export const IndexPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useAppState();

  const userContext = useContext(UserContext);
  const user = userContext.data;

  const searchParams = new URLSearchParams(location.search);

  const [profileLoading, setProfileLoading] = useState(true);
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [divisionData, setDivisionData] = useState<Division | null>(null);
  const [applicationSettingsData, setApplicationSettings] = useState<ApplicationSettings | null>(null);
  const [isSliderPlaying, setIsSliderPlaying] = useState(true);
  const [availableDivisions, setAvailableDivisions] = useState([]);
  const { isBiometryAvailable } = useBiometry();

  useEffect(() => {
    if (state && PlatformService.isMobile()) {
      if (!user && PlatformService.isAndroid()) {
        fetchProfileData({ anonymous: true });
      } else {
        fetchProfileData({ anonymous: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  /** Show error message. */
  useEffect(() => {
    if (searchParams.get('errorMessage')) {
      NotificationService.push('warning', 'Error.', searchParams.get('errorMessage'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/cse-form') {
      $(`#${CSE_MODAL_ID}`).modal('show');
    }
  }, [history.location.pathname]);

  /** Set categories array state side effect. */
  useEffect(() => {
    if (!user && PlatformService.isAndroid()) {
      fetchProfileData({ anonymous: true });
    } else {
      fetchProfileData({ anonymous: false });
    }

    return (): void => {
      setCategories(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Open native biometry window if this is available effect. */
  useEffect(() => {
    if (PlatformService.isAndroid() && !user && BiometricService.permissionGiven() && isBiometryAvailable) {
      BiometricService.useBiometric(afterBiometrySuccessEffect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BiometricService.permissionGiven(), isBiometryAvailable, user]);

  /** Effect stopping slider on opening auth modal and start on closing. */
  useEffect(() => {
    const signOnModal = $(`#${signOnModalId}`);
    const requestAccessModal = $(`#${REQUEST_ACCESS_MODAL_ID}`);

    signOnModal.on('hidden.bs.modal', function() {
      setIsSliderPlaying(true);
    });

    signOnModal.on('show.bs.modal', function() {
      setIsSliderPlaying(false);
    });

    requestAccessModal.on('hidden.bs.modal', function() {
      setIsSliderPlaying(true);
    });

    requestAccessModal.on('show.bs.modal', function() {
      setIsSliderPlaying(false);
    });

    return (): void => {
      setIsSliderPlaying(true);
    };
  }, []);

  /**
   * Effect of SSO auth triggering.
   */
  useEffect(() => {
    const ssoParams = SSOService.getSSOAuthParamsFromUrl();

    if (ssoParams) {
      SSOService.generateJWTToken(ssoParams).then(() => {
        closeAuthModal();
        fetchProfileData({ anonymous: false }).then(() => {
          FingerprintAIO.isAvailable().then(result => {
            /** Open dialog window of asking user to use biometry to for authentication if this is available. */
            if (result && TokenStorageService.get() && BiometricService.permissionGiven() === null) {
              BiometricService.openPermissionDialog(result);
            }
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  /** Method for fetching categories from server and setting it into the state. */
  const fetchProfileData = async ({ anonymous }): Promise<void> => {
    setProfileLoading(true);
    try {
      const {
        categories,
        user,
        applicationSettings,
        division,
        availableDivisions,
      } = await UserProfileService.loadUserProfileData({
        anonymous,
      });
      setCategories(categories);
      userContext.handleSetUser(user);
      setDivisionData(division);
      setAvailableDivisions(availableDivisions);
      setApplicationSettings(applicationSettings);
      setProfileLoading(false);
    } catch (error) {
      setProfileLoading(false);
    }
  };

  /** Logout user and refetch profile data for anonymous user. */
  const handleLogout = async (): Promise<void> => {
    AuthService.logout();
    userContext.handleResetUser();
    BiometricService.resetPermission();
    await fetchProfileData({ anonymous: true });

    if (PlatformService.isIOS()) {
      history.push('/auth');
    }
  };

  /**
   * Login handler method.
   *
   * @param email User email.
   * @param password User password.
   * @param rememberMe Save user data flag.
   */
  const handleLogin = async ({ email, password, rememberMe }: LoginFormData): Promise<void> => {
    setIsSliderPlaying(false);
    try {
      await AuthService.login({ email, password, rememberMe });
      await fetchProfileData({ anonymous: false });
      closeAuthModal();
    } finally {
      setIsSliderPlaying(true);
    }
  };

  /** Effect calling after successful biometry auth. */
  const afterBiometrySuccessEffect = async (): Promise<void> => {
    await fetchProfileData({ anonymous: false });
    closeAuthModal();
  };

  /** Return division service phone if this is exists in division data. */
  const getDivisionPhone = (): string | null => divisionData && divisionData.servicePhone;

  /** Render categories element or data loader. */
  const renderCategories = profileLoading ? (
    <DataLoader size={100} color="#35a847" />
  ) : (
    <Categories categories={categories} />
  );

  return (
    <AppLayout>
      <UserContext.Provider value={user}>
        {applicationSettingsData?.banner.isBannerDisplayed && (
          <div className="row">
            <AppBanner text={applicationSettingsData?.banner.bannerText} />
          </div>
        )}
        <div className="row">
          <MainSlider
            isCCTFReceiptDisplayed={applicationSettingsData?.isCCTFReceiptsIconDisplayed}
            profileLoading={profileLoading}
            handleLogout={handleLogout}
            isPlaying={isSliderPlaying}
            isRequestAccessEnabled={applicationSettingsData?.isRequestAccessEnabled}
          />
          <AuthModal
            handleAfterBiometrySuccess={(): Promise<void> => afterBiometrySuccessEffect()}
            handleLogin={handleLogin}
          />
          <DeleteAccountModal onLogout={handleLogout} />
        </div>
        {renderCategories}
        <ContactUs
          divisionPhone={getDivisionPhone()}
          isRequestAccessEnabled={applicationSettingsData?.isRequestAccessEnabled}
        />
        <RequestAccessDialog availableDivisions={availableDivisions} />
        <Route
          exact
          path="/cse-form"
          render={(): JSX.Element => <CSEDialog availableDivisions={availableDivisions} />}
        />
      </UserContext.Provider>
    </AppLayout>
  );
};

export default IndexPage;
