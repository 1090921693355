import * as Yup from 'yup';
import 'yup-phone';

export const initialFormState = {
  iHavePC: false,
  scanData: false,
  backOffice: false,
  topOffRewards: false,
};

let usersList = null;

export const formValidation = Yup.object().shape({
  salesRepresentativeFullName: Yup.string().required('Required'),
  salesRepresentativeEmail: Yup.string()
    .email('Must be a valid email.')
    .required('Required'),
  user: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string()
          .required('Required')
          .trim(),
        lastName: Yup.string()
          .required('Required')
          .trim(),
        email: Yup.string()
          .email('Must be a valid email.')
          .required('Required'),
        phone: Yup.string()
          .phone('US', true, 'Must be a valid phone number')
          .required('Required'),
        street: Yup.string()
          .required('Required')
          .trim(),
        storeName: Yup.string()
          .required('Required')
          .trim(),
        city: Yup.string()
          .required('Required')
          .trim(),
        state: Yup.string()
          .required('Required')
          .trim(),
        zip: Yup.string()
          .required('Required')
          .trim(),
        accountNumber: Yup.string()
          .required('Required')
          .trim(),
        brandMetaData: Yup.object().shape({
          coreMarkDivisionName: Yup.array().test('not-empty', 'Required', values => {
            if (!values || values.length === 0) {
              return false;
            }

            return true;
          }),
        }),
        posSystem: Yup.string()
          .required('Required')
          .trim(),
        userName: Yup.string()
          .required('Required')
          .trim()
          .test('unique-username2', 'Required', _ => {
            // The unique userName validation
            if (usersList.length > 1) {
              const uniqueUsersList = new Set(usersList.map(a => a.userName));

              return uniqueUsersList.size > 1;
            }

            return true;
          }),
        password: Yup.string()
          .required('Required')
          .matches(
            /^(?=.*\d)(?=.*[`!”"?$/%^&*()_–+={[\]}:;@‘~#|<,>.])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
            'Password must contain at 8-20 characters, one uppercase, one number and one special case character',
          ),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      }),
    )
    .test('unique-username', 'Required', values => {
      usersList = values;

      return true;
    })
    .required('Required'),
  iHavePC: Yup.boolean()
    .oneOf([true], 'Must be accepted')
    .required('Required'),
});
