import * as Yup from 'yup';

export const initialFormState = {
  name: '',
  email: '',
  customerAccountNumber: '',
  state: '',
  questionsAndNeeds: '',
};

export const formValidation = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .trim(),
  email: Yup.string()
    .email('Must be a valid email.')
    .required('Required'),
  customerAccountNumber: Yup.string()
    .required('Required')
    .trim(),
  state: Yup.string()
    .required('Required')
    .trim(),
  questionsAndNeeds: Yup.string()
    .required('Required')
    .trim(),
});
